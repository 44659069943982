/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { IInputProps } from '../common/interfaces';
import commonStyle from '../common/style';

export interface IICheckBoxStyle {
  backgroundColor: string;
  marginBottom: number;
  fontSize: number;
  height: number;
  fontColor: string;
  activeColor: string;
  width: number;
  marginHorizontal: number;
}

export interface ICheckBoxProps extends IInputProps {
  options: string[];
  style?: IICheckBoxStyle;
}

const Styles = (style?: IICheckBoxStyle) =>
  StyleSheet.create({
    root: {
      width: '95%',
      justifyContent: 'flex-start',
      flexDirection: 'row-reverse',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    checkBox: {
      minWidth: style?.width || 80,
      height: style?.height || 40,
      marginHorizontal: style?.marginHorizontal || 5,
      marginBottom: style?.marginBottom || 10,
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: style?.backgroundColor ? 'transparent' : '#ff005a',
      backgroundColor: style?.backgroundColor || 'transparent',
      borderWidth: 1,
      borderRadius: 50,
    },
    activeCheckBox: {
      borderWidth: 0,
      backgroundColor: '#05A4FF',
    },
    activeText: {
      color: style?.activeColor || '#1A1A1A',
    },
    checkBoxText: {
      color: style?.fontColor || '#88827A',
      fontSize: style?.fontSize || 16,
    },
  });

const CheckBox = ({
  text,
  onPress,
  active,
  styles,
}: {
  text: string;
  onPress: () => void;
  active: boolean;
  styles: ReturnType<typeof Styles>;
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.checkBox, active ? styles.activeCheckBox : {}]}>
      <Text
        style={[
          commonStyle.textBold,
          styles.checkBoxText,
          active ? styles.activeText : {},
        ]}>
        {text}
      </Text>
    </TouchableOpacity>
  );
};

const valueToIndex = (options: string[], values: string[]) => {
  return values.reduce((indexes, value) => {
    const index = options.findIndex((option) => option === value);
    if (index !== -1) {
      indexes.push(index);
    }
    return indexes;
  }, [] as number[]);
};

const CheckBoxGroup = ({
  value,
  onValChange,
  options,
  style,
}: ICheckBoxProps) => {
  const styles = Styles(style);
  const [input, setInput] = useState<number[]>(
    valueToIndex(options, ((value as unknown) || []) as string[]),
  );

  useEffect(() => {
    onValChange && onValChange(input.map((index) => options[index]) as any);
  }, [input]);
  return (
    <View style={styles.root}>
      {options.map((option, index) => {
        return (
          <CheckBox
            styles={styles}
            active={input.includes(index)}
            onPress={() => {
              if (input.includes(index)) {
                setInput([...input.filter((e) => e !== index)]);
              } else {
                setInput([...input, index]);
              }
            }}
            key={index}
            text={option}
          />
        );
      })}
    </View>
  );
};

export default CheckBoxGroup;
