import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  text: {
    color: '#ffffff',
    fontFamily: 'Heebo-Regular',
  },
  textBold: {
    color: '#ffffff',
    fontFamily: 'Heebo-Bold',
  },
  textSpecial: {
    color: '#ffffff',
    fontFamily: 'uniflex',
  },
});

export default styles;
