/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { StyleSheet, View, TextInput, Text } from 'react-native';
import { IInputProps } from '../common/interfaces';
import commonStyle from '../common/style';

export interface ITextInputProps extends IInputProps {
  description?: string;
  placeholder?: string;
  style?: ITextInputStyleProps;
  type?: 'text' | 'password' | 'number' | 'phone';
}
export interface ITextInputStyleProps {
  height?: number;
  bold?: boolean;
  placeholderColor?: string;
  marginBottom?: number;
  width?: number;
  multiLine?: boolean;
  color?: string;
  fontSize?: number;
  borderRadius?: number;
  borderColor?: string;
  backgroundColor?: string;
  rootWidth?: string;
}

const Styles = (style: ITextInputStyleProps) =>
  StyleSheet.create({
    root: {
      width: style.rootWidth || '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    input: {
      width: style.width || '100%',
      textAlign: 'right',
      fontSize: style.fontSize || 18,
      marginBottom: style.marginBottom || 0,
      padding: 1,
      borderColor: style.borderColor || '#ff005a',
      color: style.color || '#ffff',
      borderWidth: 1,
      paddingHorizontal: 20,
      borderRadius: style.borderRadius || 50,
      height: style.multiLine ? 'auto' : style.height || 50,
      minHeight: style.height || 50,
      backgroundColor: style.backgroundColor || 'transparent',
    },
    description: {
      paddingTop: 1,
      color: '#96999C',
      fontSize: 14,
    },
  });

const InputText = ({
  value,
  description = '',
  onValChange,
  style,
  placeholder,
  type = 'text',
}: ITextInputProps) => {
  const styles = Styles((style || {}) as ITextInputStyleProps);
  return (
    <View style={styles.root}>
      <TextInput
        keyboardType={
          type === 'number'
            ? 'numeric'
            : type === 'phone'
            ? 'phone-pad'
            : 'default'
        }
        secureTextEntry={type === 'password'}
        onChangeText={(input: string) =>
          onValChange && onValChange(input as string)
        }
        value={value}
        maxLength={120}
        autoCompleteType={type === 'phone' ? 'tel' : 'name'}
        textContentType={type === 'phone' ? 'telephoneNumber' : 'none'}
        multiline={style?.multiLine}
        placeholderTextColor={style?.placeholderColor || '#d3d3d3'}
        placeholder={placeholder}
        style={[
          style?.bold ? commonStyle.textBold : commonStyle.text,
          styles.input,
          { outline: 'none' },
        ]}
      />
      {description ? (
        <Text style={[commonStyle.text, styles.description]}>
          {description}
        </Text>
      ) : (
        <></>
      )}
    </View>
  );
};

export default InputText;
