import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';

const styles = StyleSheet.create({
  root: {
    height: 100,
    width: '100%',
    top: 0,
    alignSelf: 'center',
    position: 'absolute',
    alignItems: 'center',
    overflow: 'hidden',
  },
  shape: {
    width: 110,
    position: 'absolute',
    top: -60,
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    height: 110,
    borderBottomEndRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#05A4FF',
    overflow: 'hidden',
    transform: [{ rotate: '45deg' }],
  },
  icon: {
    position: 'absolute',
    color: '#E1E3E3',
    top: '58%',
    left: '58%',
    transform: [{ rotate: '-45deg' }],
  },
  button: {
    flex: 1,
    width: '100%',
  },
});

export interface ISearchHeaderProps {
  onPress: () => void;
}

const SearchHeader = ({ onPress }: ISearchHeaderProps) => {
  return (
    <View style={styles.root}>
      <View style={styles.shape}>
        <TouchableOpacity style={styles.button} onPress={onPress}>
          <FontAwesome
            style={styles.icon}
            name="search"
            size={25}
            color="black"
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default SearchHeader;
