/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { useInterval } from 'react-interval-hook';
import { Entypo } from '@expo/vector-icons';
import { IInputProps } from '../common/interfaces';

import commonStyle from '../common/style';

export interface INumericInputProps extends IInputProps {
  style?: INumericInputStyleProps;
  customText?: (str: string) => string;
  intiValue?: number;

}
export interface INumericInputStyleProps {
  height: number;
  bold: boolean;
  marginBottom: number;
  color: string;
  fontSize: number;
}

const Styles = (style: INumericInputStyleProps) =>
  StyleSheet.create({
    root: {},
    inputContainer: {
      marginBottom: style.marginBottom || 0,
      borderColor: '#ff005a',
      width: 180,
      borderWidth: 1,
      borderRadius: 50,
      justifyContent: 'center',
      overflow: 'hidden',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      height: style.height || 50,
    },
    increaseContainer: {
      flex: 0.35,
      height: '80%',
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: '#ff005a',
      borderRightWidth: 1,
    },
    valueContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    decreaseContainer: {
      flex: 0.35,
      height: '80%',
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: '#ff005a',
      borderLeftWidth: 1,
    },
    text: {
      fontSize: style.fontSize || 16,
      color: style.color || '#ffff',
    },
  });

const NumericInput = ({
  value,
  onValChange,
  style,
  intiValue = 160,
  customText = (val: string) => {
    return `${(val / 100).toFixed(2)} מ`;
  },
}: INumericInputProps) => {
  const [input, setInput] = useState(
    ((value as unknown) as number) || intiValue,
  );
  const styles = Styles((style || {}) as INumericInputStyleProps);
  useEffect(() => {
    onValChange && onValChange((input as unknown) as string);
  }, [input]);
  useEffect(() => {
    setInput(((value as unknown) as number) || intiValue);
  }, [value]);
  const increaseInterval = useInterval(
    () => {
      setInput(input + 1);
    },
    125,
    { autoStart: false },
  );
  const decreaseInterval = useInterval(
    () => {
      setInput(input - 1);
    },
    100,
    { autoStart: false },
  );
  return (
    <View style={styles.root}>
      <View style={[styles.inputContainer]}>
        <View style={styles.decreaseContainer}>
          <TouchableOpacity
            style={{
              flex: 1,
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => setInput(input - 1)}>
            <Entypo name="minus" size={20} color="#ff005a" />
          </TouchableOpacity>
        </View>
        <View style={styles.valueContainer}>
          <Text
            style={[
              style?.bold ? commonStyle.textBold : commonStyle.text,
              styles.text,
            ]}>
            {customText(input as any)}
          </Text>
        </View>
        <View style={styles.increaseContainer}>
          <TouchableOpacity
            style={{
              flex: 1,
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => setInput(input + 1)}>
            <Entypo name="plus" size={20} color="#ff005a" />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default NumericInput;
