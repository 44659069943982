/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  StyleSheet,
  View,
  ImageBackground,
  Platform,
  Text,
  TouchableOpacity,
} from 'react-native';

import * as ImagePicker from 'expo-image-picker';
import { FontAwesome } from '@expo/vector-icons';
import { Spinner } from '@ui-kitten/components';
import commonStyle from '../common/style';
import { IInputProps } from '../common/interfaces';
import Blank from '../../assets/pictureBlank.png';
import {
  deleteImagesByUrl,
  uploadImagesByUser,
} from '../services/firebase.services';

const styles = StyleSheet.create({
  imageBlank: {
    width: 40,
    height: 40,
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'stretch',

    justifyContent: 'center',
    alignItems: 'center',
  },
  addImageView: {
    width: 60,
    height: 65,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageContainer: {
    width: 95,
    height: 65,
    marginHorizontal: 10,

    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#262626',
    borderWidth: 1,
    borderColor: '#FB0059',
  },
  imagesContainer: {
    marginVertical: 15,
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'row-reverse',
  },
  imageViewWrap: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 14,
    paddingTop: 5,
    textAlign: 'center',
  },
  addView: {
    width: 40,
    height: 40,
    borderRadius: 100,
    elevation: 5,
    backgroundColor: '#FB0059',
  },
  addButton: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export interface IImagesInputProps extends IInputProps {
  imageCount: number;
  userId: any;
  type: 'profile' | 'album';
}

const Image = ({
  value,
  upload,
  onPick,
  type,
  index,
  OnDelete,
}: {
  upload: boolean;
  type: 'profile' | 'album';
  value: any;
  index: number;
  onPick: (value: any) => void;
  OnDelete: () => any;
}) => {
  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        await ImagePicker.requestCameraRollPermissionsAsync();
      }
    })();
  }, []);

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [2.5, 2],
      quality: 1,
    });
    if (!result.cancelled) {
      onPick(result.uri);
    }
  };
  return (
    <View style={styles.imageViewWrap}>
      {value || index === 0 ? (
        <TouchableOpacity
          onPress={async () => {
            if (!value) {
              return pickImage();
            }
            if (upload) OnDelete();
            return null;
          }}
          style={styles.imageContainer}>
          {value ? (
            <ImageBackground
              resizeMode="stretch"
              source={{ uri: value || Blank } as any}
              style={styles.image}>
              {upload ? (
                <FontAwesome name="close" size={35} color="#E3F0EF" />
              ) : (
                <Spinner size="medium" status="control" />
              )}
            </ImageBackground>
          ) : (
            <FontAwesome name="plus" size={32} color="#E3F0EF" />
          )}
        </TouchableOpacity>
      ) : (
        <View style={styles.addImageView}>
          <View style={styles.addView}>
            <TouchableOpacity
              onPress={async () => {
                if (!value) {
                  return pickImage();
                }
                if (upload) OnDelete();
                return null;
              }}
              style={styles.addButton}>
              <FontAwesome name="plus" size={20} color="#E3F0EF" />
            </TouchableOpacity>
          </View>
        </View>
      )}
      {index === 0 ? (
        <Text style={[commonStyle.text, styles.text]}>
          {type === 'profile' ? 'תמונת פרופיל' : 'העלאה לאלבום'}
        </Text>
      ) : (
        <Text style={[commonStyle.text, styles.text]} />
      )}
    </View>
  );
};

const ImagesInput = ({
  imageCount = 3,
  onValChange,
  value,
  type,
  userId,
}: IImagesInputProps) => {
  const [images, setImages] = useState(
    (value || [])?.length < imageCount
      ? [
          ...(((value || ([] as any)).map((val: any) => {
            return { ...val, upload: true };
          }) as any) || []),
          { image: null },
        ]
      : [
          ...(((value || ([] as any)).map((val: any) => {
            return { ...val, upload: true };
          }) as any) || []),
        ],
  );
  const onPick = (img: any, index: number) => {
    const _images = images;
    _images[index] = { image: img, upload: false };
    if (
      _images.every((image) => image.image) &&
      _images.length !== imageCount
    ) {
      _images.push({ image: null });
    }
    setImages([..._images]);
    Promise.all(
      _images.map(async (image) => {
        if (!image.upload && image.image) {
          const urls = await uploadImagesByUser([image.image], type, userId);
          return { ...image, image: urls[0], upload: true };
        }
        return image;
      }),
    ).then((imagePromise) => {
      setImages([...imagePromise]);
      onValChange &&
        onValChange(imagePromise.filter((imageObj) => imageObj.image) as any);
    });
  };

  const OnDelete = (index: number) => {
    console.log({ images });
    const deleteImageIndex = images.findIndex((image, imageIndex) => {
      return imageIndex === index;
    });
    let _images = images;
    _images[deleteImageIndex] = { ..._images[deleteImageIndex], upload: false };
    setImages([...images]);
    deleteImagesByUrl([images[deleteImageIndex].image])
      .then(() => {
        _images = images.filter((image, imageIndex) => imageIndex !== index);
        if (
          _images.every((imageObj) => imageObj.image) &&
          _images.length !== imageCount
        ) {
          _images.push({ image: null });
        }
        setImages([..._images]);
        onValChange &&
          onValChange(_images.filter((imageObj) => imageObj.image) as any);
      })
      .catch(() => {
        _images = images.filter((image, imageIndex) => imageIndex !== index);
        if (
          _images.every((imageObj) => imageObj.image) &&
          _images.length !== imageCount
        ) {
          _images.push({ image: null });
        }
        setImages([..._images]);
        onValChange &&
          onValChange(_images.filter((imageObj) => imageObj.image) as any);
      });
  };
  return (
    <View style={styles.imagesContainer}>
      {images.map((image, key) => {
        return (
          <Image
            upload={image.upload}
            value={image.image}
            index={key}
            OnDelete={() => {
              OnDelete(key);
            }}
            type={type}
            onPick={(img) => onPick(img, key)}
            key={key}
          />
        );
      })}
    </View>
  );
};

export default ImagesInput;
