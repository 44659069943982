/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-native/no-inline-styles */
import { FontAwesome } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import ImageCrop from 'react-image-crop-component';
import 'react-image-crop-component/style.css';
import {
  Dimensions,
  ImageBackground,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import ModalWeb from 'modal-react-native-web';
import { isEmpty } from 'lodash';
import commonStyle from '../common/style';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.95)',
  },
  textButton: {
    fontSize: 26,
  },
  modal: {
    flex: 1,
    width: '100%',
  },
  modalZoom: {
    flex: 1,
    width: '100%',
  },
  webImage: {
    width: '100%',
    flex: 1,
  },
  innerView: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    resizeMode: 'contain',
    height: undefined,
    aspectRatio: 1,
  },
  loading: {
    width: '100%',
    flex: 1,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },
  close: {
    position: 'absolute',
    top: 12,
    left: 15,
  },
  spinnerView: {
    width: 180,
    height: 180,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
  button: {
    height: 50,
    width: 140,
    borderRadius: 50,
    elevation: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
    backgroundColor: '#F40257',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerButton: {
    flex: 1,
    width: '120%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonDisabled: {
    backgroundColor: '#5d5d5d',
  },
});

export interface IImagePreviewProps {
  onPress: () => void;
  imageUri: { uri: string };
}

const ImageCropEdit = ({
  visible,
  setVisible,
  onCrop,
  image,
}: {
  visible: boolean;
  image: string;
  onCrop: (img: any) => void;

  setVisible: () => void;
}) => {
  const [imageUrl, setImageUrl] = useState(image);
  const [crop, setCropImage] = useState('');
  useEffect(() => {
    console.log({ image });

    setImageUrl(image);
    return () => {
      setImageUrl('');
    };
  }, [image]);

  const onCropped = (e: any) => {
    // const { image } = e;
    fetch(e.image)
      .then((res) => res.blob())
      .then((res) => {
        const blobUrl = URL.createObjectURL(res);
        setCropImage(blobUrl);
        // onCrop(blobUrl);
      });
    // console.log({ blobUrl });

    // const image_data = e.data;
  };
  return (
    <ModalWeb
      style={styles.modal}
      animationType="fade"
      transparent
      statusBarTranslucent
      onRequestClose={setVisible}
      visible={visible}>
      <View style={styles.root}>
        <View style={styles.innerView}>
          {/* <ImageBackground
            resizeMode="stretch"
            source={{ uri: imageUrl }}
            style={[styles.image, styles.webImage]}>
            <TouchableOpacity
              style={{ flex: 0.15, width: '30%' }}
              onPress={setVisible}>
              <FontAwesome
                style={styles.close}
                name="close"
                size={36}
                color="#EB0454"
              />
            </TouchableOpacity>
          </ImageBackground> */}
          {!isEmpty(imageUrl) ? (
            <View
              style={{
                flex: 0.9,
                width: '90%',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Text
                style={[
                  commonStyle.text,
                  styles.textButton,
                  { paddingBottom: 10 },
                ]}>
                סמן את החלק הרצוי מהתמונה
              </Text>
              <ImageCrop
                maxWidth={`${Dimensions.get('window').width / 1.8}px`}
                src={imageUrl}
                square
                onCrop={onCropped}
                resize
              />
              <View
                style={[
                  styles.button,
                  { marginTop: 20 },
                  isEmpty(crop) ? styles.buttonDisabled : {},
                ]}>
                <TouchableOpacity
                  disabled={isEmpty(crop)}
                  onPress={() => onCrop(crop)}
                  style={styles.innerButton}>
                  <Text style={[commonStyle.text, styles.textButton]}>שלח</Text>
                </TouchableOpacity>
              </View>
            </View>
          ) : (
            <></>
          )}
        </View>
      </View>
    </ModalWeb>
  );
};

export default ImageCropEdit;
