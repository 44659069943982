/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/no-array-index-key */
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  FlexAlignType,
} from 'react-native';
import { IInputProps } from '../common/interfaces';
import commonStyle from '../common/style';

export interface IRadioGroup extends IInputProps {
  options: string[];
  style?: IRadioStyle;
  array?: boolean;
}

export interface IRadioStyle {
  backgroundColor: string;
  marginBottom: number;
  fontSize: number;
  height: number;
  fontColor: string;
  activeColor: string;
  width: number;
  marginHorizontal: number;
  alignItems: FlexAlignType;
  activeBackgroundColor: string;
  justifyContent:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
}

const Styles = (style?: IRadioStyle) =>
  StyleSheet.create({
    root: {
      width: '95%',
      justifyContent: style?.justifyContent || 'flex-start',
      flexDirection: 'row-reverse',
      alignItems: style?.alignItems || 'flex-start',
      flexWrap: 'wrap',
    },
    radio: {
      minWidth: style?.width || 80,
      height: style?.height || 40,
      marginHorizontal: style?.marginHorizontal || 5,
      marginBottom: style?.marginBottom || 10,
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: style?.backgroundColor ? 'transparent' : '#ff005a',
      backgroundColor: style?.backgroundColor || 'transparent',
      borderWidth: 1,
      borderRadius: 50,
    },
    activeRadio: {
      borderWidth: 0,
      backgroundColor: style?.activeBackgroundColor || '#05A4FF',
    },
    activeText: {
      color: style?.activeColor || '#1A1A1A',
    },
    radioText: {
      color: style?.fontColor || '#88827A',
      fontSize: style?.fontSize || 16,
    },
  });

const Radio = ({
  text,
  onPress,
  active,
  styles,
}: {
  text: string;
  onPress: () => void;
  active: boolean;
  styles: ReturnType<typeof Styles>;
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.radio, active ? styles.activeRadio : {}]}>
      <Text
        style={[
          commonStyle.textBold,
          styles.radioText,
          active ? styles.activeText : {},
        ]}>
        {text}
      </Text>
    </TouchableOpacity>
  );
};

const RadioGroup = ({
  value,
  onValChange,
  options,
  style,
  array = false,
}: IRadioGroup) => {
  const styles = Styles(style);
  const [input, setInput] = useState(
    options.findIndex(
      (option) =>
        option === (array ? (value && !isEmpty(value) ? value[0] : '') : value),
    ),
  );
  useEffect(() => {
    setInput(
      options.findIndex(
        (option) =>
          option ===
          (array ? (value && !isEmpty(value) ? value[0] : '') : value),
      ),
    );
  }, [value, array]);
  useEffect(() => {
    onValChange &&
      onValChange(array ? [options[input]] : (options[input] as any));
  }, [input]);
  return (
    <View style={styles.root}>
      {options.map((option, index) => {
        return (
          <Radio
            styles={styles}
            active={index === input}
            onPress={() => {
              setInput(index);
            }}
            key={index}
            text={option}
          />
        );
      })}
    </View>
  );
};

export default RadioGroup;
