/* eslint-disable no-empty */
import * as React from 'react';
import { useEffect, useState } from 'react';
import AsyncStorage from '@react-native-community/async-storage';
import { AccountContext } from './account';

const filterObjKey = 'BarFilterObjKey';

export interface IFiltersContext {
  filters: {
    age: number[];
    length: number[];
    body: string[];
    interesting: string;
    distance: number;
    status: string[];
    relationship: string[];
  };
  setFilters: (filters: any) => void;
}

export const FilterContext = React.createContext<IFiltersContext>({
  filters: {
    age: [18, 99],
    length: [110, 240],
    body: [],
    interesting: '',
    distance: 0,
    status: [],
    relationship: [],
  },
  setFilters: () => {},
});

export interface IFilterContextProps {
  children: any;
}

const getFilters = async () => {
  try {
    const jsonValue = await AsyncStorage.getItem(filterObjKey);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    return null;
  }
};

export const FilterProvider = ({ children }: IFilterContextProps) => {
  const [filters, setFilters] = useState({
    age: [18, 99],
    length: [110, 240],
    body: [],
    interesting: '',
    distance: 0,
    status: [],
    relationship: [],
  });

  useEffect(() => {
    (async () => {
      const filtersObj = await getFilters();
      if (filtersObj) {
        setFilters(filtersObj);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const jsonValue = JSON.stringify(filters);
        await AsyncStorage.setItem(filterObjKey, jsonValue);
      } catch (e) {}
    })();
  }, [filters]);

  return (
    <FilterContext.Provider
      value={{
        filters,
        setFilters,
      }}>
      {children}
    </FilterContext.Provider>
  );
};
