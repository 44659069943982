/* eslint-disable react-native/no-inline-styles */
/* eslint-disable max-classes-per-file */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prefer-stateless-function */
import * as React from 'react';
import { FlatList, View, StyleSheet, Dimensions } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { v4 } from 'react-native-uuid';
import { uniq, uniqBy } from 'lodash';
import CatalogItem from './catalogItem';
import { FilterContext } from '../context/filters';
import firebase from '../services/firebase';
import {
  getAlbumImages,
  AccountContext,
  getProfileImage,
} from '../context/account';

const styles = StyleSheet.create({
  root: {
    flex: 0.92,
  },
  fadeBottom: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 120,
    zIndex: 5,
  },
  fadeTop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: 60,
    zIndex: 5,
  },
});

const userCatalogQuery = firebase.functions().httpsCallable('userCatalog');

class CatalogClass extends React.Component<any, any> {
  onEndReachedCalledDuringMomentum: boolean;

  pages: any[];

  catalogRef = React.createRef();

  constructor(
    props: { navigation: any; account: any; filters: any } | Readonly<{}>,
  ) {
    super(props);
    this.onEndReachedCalledDuringMomentum = true;
    this.pages = [];
    this.state = {
      users: [],
      emptyFetch: 0,
      isLoading: true,
      page: 0,
      limitUsers: 100,
      fetchSize: 10,
    };
  }

  async componentDidMount() {
    const db = firebase.firestore();
    db.collection('Config')
      .doc('general')
      .get()
      .then((res: any) => {
        console.log({ ...res.data() });

        this.setState(
          {
            limitUsers: res.data().limitUsers || 0,
            fetchSize: res.data().fetchSize || 10,
            page: 1,
          },
          () => this.fetchMore(),
        );
      });
  }

  // shouldComponentUpdate(nextProps: { account: any; filters: any }) {
  //   const { filters, account } = this.props;
  //   if (nextProps.account !== account) {
  //     return true;
  //   }
  //   if (nextProps.filters !== filters) {
  //     return true;
  //   }
  //   return true;
  // }

  async componentDidUpdate(nextProps: any) {
    const { filters, account } = this.props;
    if (nextProps.account !== account) {
      this.pages = [];

      this.setState(
        {
          users: [],
          isLoading: true,
          emptyFetch: 0,
          page: 1,
        },
        () => this.fetchMore(),
      );
      return true;
    }
    if (nextProps.filters !== filters) {
      this.pages = [];
      this.setState(
        {
          users: [],
          isLoading: true,
          emptyFetch: 0,
          page: 1,
        },
        () => this.fetchMore(),
      );
      return true;
    }
    return false;
  }

  async fetchMore() {
    const { filters, account } = this.props;
    const { page, users, emptyFetch, limitUsers, fetchSize } = this.state;
    console.log({
      page,
      emptyFetch,
      users: users.length,
      skip: (page - 1) * fetchSize,
    });
    if (emptyFetch >= 1) {
      return;
    }

    if (!this.pages.includes(page) && users.length < limitUsers) {
      this.pages.push(page);

      const usersCatalog = await userCatalogQuery({
        userId: account?.uid,
        age: filters.age,
        length: filters.length,
        interesting: filters.interesting,
        body: filters.body,
        // status: filters.status,
        relationship: filters.relationship,
        size: fetchSize,
        skip: (page - 1) * fetchSize,
      });
      console.log({ x: usersCatalog.data });

      if (usersCatalog.data.length > 0) {
        const usersCatalogNew = await Promise.all(
          usersCatalog.data.map(async (user: any) => {
            const [profile, album] = await Promise.all([
              getProfileImage(user.uid, user.sex),
              getAlbumImages(user.uid, user.sex),
            ]);
            return { ...user, album, profile };
          }),
        );
        this.setState({
          users: users.concat(usersCatalogNew),
          isLoading: false,
          emptyFetch: 0,
        });
        //   } else {
        //     this.setState({
        //       isLoading: false,
        //     });
        //   }
      } else {
        this.setState((prevState: any) => ({
          emptyFetch: prevState.emptyFetch + 1,
        }));

        // setTimeout(() => {
        //   this.setState(
        //     () => ({
        //       page: page + 1,
        //     }),
        //     () => this.fetchMore(),
        //   );
        // }, 1000);
      }
    }
    this.setState({
      isLoading: false,
    });
  }

  render() {
    const {
      users,
      isLoading,
      page,
      emptyFetch,
      fetchSize,
      limitUsers,
    } = this.state;
    const { navigation } = this.props;
    const { height } = Dimensions.get('window');

    return (
      <View style={[styles.root, { height }]}>
        {users.length ? (
          <LinearGradient
            colors={['rgba(0,0,0,0.4)', 'rgba(255,255,255,0)']}
            style={styles.fadeTop}
          />
        ) : (
          <></>
        )}
        {users.length > 3 ? (
          <LinearGradient
            colors={['rgba(255,255,255,0)', 'rgba(0,0,0,0.6)']}
            style={styles.fadeBottom}
          />
        ) : (
          <></>
        )}
        <FlatList
          getItemLayout={(data, index) => ({
            length: 120,
            offset: 120 * index,
            index,
          })}
          style={{ flex: 1 }}
          refreshing={isLoading}
          removeClippedSubviews
          ref={this.catalogRef as any}
          initialNumToRender={users.length}
          initialScrollIndex={0.0}
          // scrollEnabled={!isLoading}
          onMomentumScrollBegin={() => {
            this.onEndReachedCalledDuringMomentum = false;
          }}
          invertStickyHeaders
          onEndReachedThreshold={0.5}
          onEndReached={() => {
            if (emptyFetch >= 1) {
              return;
            }
            if (isLoading) {
              return;
            }
            if (users.length > limitUsers) {
              return;
            }
            if (this.pages.includes(page + 1)) {
              return;
            }

            this.setState({ isLoading: true });

            this.setState(
              () => ({
                page: page + 1,
              }),
              () => this.fetchMore(),
            );
            this.onEndReachedCalledDuringMomentum = true;
          }}
          keyExtractor={(item) => String(item.uid || v4())}
          data={users.concat(
            isLoading ? new Array(fetchSize).fill('none') : [],
          )}
          maintainVisibleContentPosition={{
            minIndexForVisible: 0,
          }}
          renderItem={({ item }: any) => (
            <CatalogItem
              loading={item === 'none' && isLoading}
              item={item}
              onPress={() => {
                navigation.navigate('Chat', {
                  userId: item.uid,
                  user: item,
                });
              }}
            />
          )}
        />
      </View>
    );
  }
}

export default React.memo(({ navigation }: { navigation: any }) => {
  const { account } = React.useContext(AccountContext);
  const { filters } = React.useContext(FilterContext);
  return (
    <CatalogClass navigation={navigation} account={account} filters={filters} />
  );
});
