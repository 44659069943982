import { Asset } from 'expo-asset';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, TouchableOpacity, Text, View } from 'react-native';
import { Skeleton } from 'react-native-magnus';
import * as ImageManipulator from 'expo-image-manipulator';
import commonStyle from '../common/style';

const styles = StyleSheet.create({
  root: {
    width: 50,
    height: 50,
    borderRadius: 50,
    overflow: 'hidden',
    elevation: 2,
  },
  image: {
    width: 50,
    height: 50,
    resizeMode: 'stretch',
  },
  view: {
    width: '20%',
    height: 100,
    marginTop: 45,
    marginRight: 8,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  text: {
    paddingTop: 4,
    width: 90,
    textAlign: 'center',
  },
});

export interface IAvatarProps {
  onPress: () => void;
  imageUri: { uri: string };
  name: string;
}

const Avatar = ({ onPress, imageUri, name }: IAvatarProps) => {
  const [image, setImage] = useState('');
  useEffect(() => {
    if (!isEmpty(imageUri)) {
      Asset.loadAsync(imageUri.uri)
        .then(async (assets) => {
          if (!isEmpty(assets)) {
            setImage(assets[0].localUri as any);
          }
        })
        .catch(() => {
          setImage(imageUri.uri);
        });
    }
    return () => {
      setImage('');
    };
  }, [imageUri]);
  return (
    <View style={styles.view}>
      <TouchableOpacity onPress={onPress} style={styles.root}>
        {isEmpty(image) ? (
          <Skeleton.Circle h={50} w={50} bg="gray900" />
        ) : (
          <Image
            style={styles.image}
            source={{
              uri: image,
            }}
          />
        )}
      </TouchableOpacity>

      <Text numberOfLines={1} style={[commonStyle.text, styles.text]}>
        {name}
      </Text>
    </View>
  );
};

export default Avatar;
