/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useRef, useState } from 'react';
import {
  StyleSheet,
  View,
  Text,
  KeyboardAvoidingView,
  TouchableOpacity,
  Platform,
  Modal,
} from 'react-native';
import DatePicker from 'react-native-modern-datepicker';
import moment from 'moment';
import { TextInputMask } from 'react-native-masked-text';
import { IInputProps } from '../common/interfaces';

import commonStyle from '../common/style';
import { useKeyboard } from '../common/hooks';
import { AntDesign } from '@expo/vector-icons';
import NumericInput from './numericInput';

export interface IDateTimeInputProps extends IInputProps {
  style?: IDateTimeInputStyleProps;
  mode?: 'dateInput' | 'count';
}
export interface IDateTimeInputStyleProps {
  height: number;
  bold: boolean;
  marginBottom: number;
  color: string;
  fontSize: number;
}

const Styles = (style: IDateTimeInputStyleProps) =>
  StyleSheet.create({
    modalView: {
      width: '100%',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.7)',
    },
    inputContainer: {
      marginBottom: style.marginBottom || 0,
      borderColor: '#ff005a',
      width: 200,
      borderWidth: 1,
      borderRadius: 50,
      justifyContent: 'center',
      overflow: 'hidden',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      height: style.height || 50,
    },
    text: {
      fontSize: style.fontSize || 16,
      color: style.color || '#ffff',
    },
    modal: {
      flex: 1,
      width: '100%',
    },
    innerView: {
      width: '90%',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    },
    textInputStyle: {
      color: style.color || '#ffff',
    },
    calenderButton: {
      position: 'absolute',
      right: 12,
      height: '100%',
      justifyContent: 'center',
      width: 30,
    },
  });

const InputDate = ({
  value,
  onValChange,
  style,
  mode = 'dateInput',
}: IDateTimeInputProps) => {
  const [input, setInput] = useState(((value as unknown) as Date) || null);
  const [show, setShow] = useState(false);
  const [inputDate, setInputDate] = useState(
    moment((value as unknown) as Date).format('DD/MM/YYYY') || '',
  );
  const datetimeField = useRef(null);
  const isKeyboardVisible = useKeyboard();
  const styles = Styles((style || {}) as IDateTimeInputStyleProps);

  useEffect(() => {
    setInputDate(moment((input as unknown) as Date).format('DD/MM/YYYY'));
    onValChange && onValChange((input as unknown) as string);
  }, [input]);
  useEffect(() => {
    setInput(((value as unknown) as Date) || null);
  }, [value]);
  useEffect(() => {
    if (inputDate.length === 10) {
      setInput(moment(inputDate, 'DD/MM/YYYY', true).toDate() as any);
    }
  }, [inputDate]);

  if (mode === 'count') {
    return (
      <NumericInput
        style={{
          height: 40,
          bold: true,
          color: '#88827A',
          marginBottom: 10,
          fontSize: 16,
        }}
        customText={String}
        intiValue={30}
        value={(input ? moment().year() - moment(input).year() : 18) as any}
        onValChange={(val: any) => {
          const date = moment().subtract(val, 'year').toDate();
          setInput(date);

          // setValues && setValues({ ...values, length: val });
        }}
      />
    );
  }

  return (
    <>
      {show ? (
        <Modal
          style={styles.modal}
          animationType="fade"
          transparent
          statusBarTranslucent
          onRequestClose={() => {
            setShow(false);
          }}
          visible={show}>
          <View style={styles.modalView}>
            <View
              style={[
                styles.innerView,
                isKeyboardVisible ? { paddingBottom: 220 } : {},
              ]}>
              <DatePicker
                options={{
                  backgroundColor: '#1A1A1A',
                  textHeaderColor: '#05A4FF',
                  textDefaultColor: '#D1D1D1',
                  selectedTextColor: '#fff',
                  mainColor: '#05A4FF',
                  textSecondaryColor: '#D1D1D1',
                  borderColor: 'rgba(122, 146, 165, 0.1)',
                  defaultFont: 'Heebo-Regular',
                  headerFont: 'Heebo-Bold',
                }}
                mode="calendar"
                minuteInterval={30}
                current={
                  input
                    ? moment(input).format('YYYY-MM-DD')
                    : moment(new Date(Date.now())).format('YYYY-MM-DD')
                }
                selected={
                  input
                    ? moment(input).format('YYYY-MM-DD')
                    : moment(new Date(Date.now())).format('YYYY-MM-DD')
                }
                onDateChange={(date: any) => {
                  setTimeout(() => {
                    setInput(new Date(date));
                    setShow(false);
                  }, 200);
                }}
                style={{ borderRadius: 20 }}
              />
            </View>
          </View>
        </Modal>
      ) : (
        <></>
      )}
      <View style={styles.root}>
        <View style={[styles.inputContainer]}>
          <TextInputMask
            type="datetime"
            placeholder="DD/MM/YY"
            options={{
              format: 'DD/MM/YYYY',
            }}
            value={inputDate as string}
            onChangeText={(text) => {
              setInputDate(text);
            }}
            placeholderTextColor={style?.color || '#ffff'}
            style={styles.textInputStyle}
            ref={datetimeField}
          />
          <TouchableOpacity
            onPress={() => setShow(true)}
            style={styles.calenderButton}>
            <AntDesign name="calendar" size={19} color="#ff005a" />
          </TouchableOpacity>

          {/* <TouchableOpacity
            onPress={() => setShow(true)}
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}>
            <Text
              style={[
                style?.bold ? commonStyle.textBold : commonStyle.text,
                styles.text,
              ]}>
              {input ? moment(input).format('DD/MM/YYYY') : 'הכנס תאריך'}
            </Text>
          </TouchableOpacity> */}
        </View>
      </View>
    </>
  );
};

export default InputDate;
