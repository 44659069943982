/* eslint-disable @typescript-eslint/no-use-before-define */
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { isEmpty } from 'lodash';
import firebase from '../../../services/firebase';

export default async (
  values: any,
  context: any,
  setContext: (key: any, val: any) => void,
  next: (end: boolean) => void,
) => {
  return context.emailLogin
    ? emailUser(values, context, setContext, next)
    : numberUser(values, context, setContext, next);
};

const emailUser = async (
  values: { email?: string; password?: string } = {},
  context: any,
  setContext: (key: any, val: any) => void,
  next: (end: boolean) => void,
) => {
  sendVerificationEmail(values.email, values.password).then((res) => {
    setContext('emailResponse', res);
    next(false);
  });

  return { status: 'ok' };
};

const numberUser = async (
  values: { userName?: string; phone?: string } = {},
  context: any,
  setContext: (key: any, val: any) => void,
  next: (end: boolean) => void,
) => {
  const phoneUtil = PhoneNumberUtil.getInstance();

  if (isEmpty(values.phone)) {
    return { status: 'error', title: 'שגיאה', msg: 'לא הוכנס מספר טלפון' };
  }

  try {
    if (
      !phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(values.phone, 'IL'),
        'IL',
      )
    ) {
      return {
        status: 'error',
        title: 'שגיאה',
        msg: 'הוכנס מספר טלפון לא תקין',
      };
    }
  } catch {
    return {
      status: 'error',
      title: 'שגיאה',
      msg: 'הוכנס מספר טלפון לא תקין',
    };
  }

  sendVerification(
    phoneUtil.format(
      phoneUtil.parse(values.phone, 'IL'),
      PhoneNumberFormat.E164,
    ) as string,
    (window as any).recaptchaVerifier,
  ).then((verificationId) => {
    setContext('verificationId', verificationId);
    next(false);
  });
  return { status: 'ok' };
};

export const parsePhoneNumber = (number: string) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    return phoneUtil.format(
      phoneUtil.parse(number, 'IL'),
      PhoneNumberFormat.NATIONAL,
    );
  } catch {
    return number;
  }
};

export const sendVerification = async (
  phoneNumber: string,
  recaptchaRef: any,
) => {
  const phoneProvider = new (firebase as any).auth.PhoneAuthProvider();
  console.log(phoneNumber, recaptchaRef);

  return phoneProvider.verifyPhoneNumber(phoneNumber, recaptchaRef);
};

export const sendVerificationEmail = async (email?: string, password?: any) => {
  const authProvider = (firebase as any).auth();
  return authProvider
    .createUserWithEmailAndPassword(email, password)
    .catch(() => {
      return authProvider.signInWithEmailAndPassword(email, password);
    });
};
