import '@firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyBpMOy31TgZASmOMZoD0X6L1QuMdC6144M',
  authDomain: 'bar-app-7d367.firebaseapp.com',
  databaseURL: 'https://bar-app-7d367.firebaseio.com',
  projectId: 'bar-app-7d367',
  storageBucket: 'bar-app-7d367.appspot.com',
  messagingSenderId: '608830049701',
  appId: '1:608830049701:web:47f2d92a8411f91d412c4c',
  measurementId: 'G-KW2LK2V608',
};
if (!firebase?.apps?.length) {
  (firebase as any).initializeApp(firebaseConfig);
}

export default firebase as any;
