/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { IUserInfo } from '../common/interfaces';
import firebase from '../services/firebase';

export interface IAuthContext {
  userInfo: IUserInfo;
  isSignout: boolean;
  autoLogin: boolean;
  userToken: string | null;
  isLoading: boolean;
  setAutoLogin: (state: boolean) => void;
  logout: () => void;
  emailLogin: boolean;
  setEmailLogin: (state: boolean) => void;
}

export const AuthContext = React.createContext<IAuthContext>({
  userInfo: {},
  isSignout: true,
  userToken: null,
  autoLogin: true,
  isLoading: true,
  setAutoLogin: () => {},
  logout: () => {},
  emailLogin: false,
  setEmailLogin: () => {},
});

export interface IAuthContextProps {
  children: any;
}

export const AuthProvider = ({ children }: IAuthContextProps) => {
  const [userInfo, setUserInfo] = useState({});
  const [autoLogin, setAutoLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [emailLogin, setEmailLogin] = useState(false);
  useEffect(() => {
    const subscriber = firebase
      .auth()
      .onAuthStateChanged(onAuthStateChanged as any);
    return subscriber;
  }, []);
  const onAuthStateChanged = async (user: any) => {
    if (user && user.uid) {
      setUserInfo(user);
    } else {
      setUserInfo({});
    }
    setIsLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        userInfo: userInfo,
        isSignout: true,
        autoLogin: autoLogin,
        setAutoLogin,
        emailLogin,
        setEmailLogin: setEmailLogin as any,
        userToken: null,
        isLoading: isLoading,
        logout: () => {
          setAutoLogin(false);
          firebase
            .auth()
            .signOut()
            .then(() => {
              setUserInfo({});
            });
        },
      }}>
      {children}
    </AuthContext.Provider>
  );
};
