import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View, Vibration } from 'react-native';
import { Octicons } from '@expo/vector-icons';

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  sideButton: {
    width: 80,
    height: 28,
    position: 'absolute',
    left: -50,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  selected: {
    borderRadius: 30,
    elevation: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
    backgroundColor: '#05A4FF',
  },
  icon: {
    paddingRight: 6,
  },
});

export interface ISideButtonProps {
  active: boolean;
  onChange: (status: boolean) => void;
  selectedIcon: any;
  unSelectedIcon: any;
  selectedColor: string;
  unSelectedColor: string;
}

const SideButton = ({
  active,
  selectedIcon,
  unSelectedIcon,
  unSelectedColor,
  selectedColor,
  onChange,
}: ISideButtonProps) => {
  return (
    <TouchableOpacity onPress={() => onChange(!active)} style={styles.root}>
      <View style={[styles.sideButton, active ? styles.selected : {}]}>
        {React.cloneElement(active ? selectedIcon : unSelectedIcon, {
          size: 20,
          style: styles.icon,
          color: active ? selectedColor : unSelectedColor,
        })}
      </View>
    </TouchableOpacity>
  );
};

export default SideButton;
