/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
import { AntDesign, Entypo, MaterialIcons } from '@expo/vector-icons';
import React, { Component } from 'react';
import {
  TextInput,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
  Text,
  Platform,
  KeyboardAvoidingView,
  Keyboard,
  Dimensions,
} from 'react-native';
import { isEmpty } from 'lodash';
import { v4 } from 'react-native-uuid';
import * as ImagePicker from 'expo-image-picker';

import * as ImageManipulator from 'expo-image-manipulator';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import { BlurView } from 'expo-blur';
import { IUserAccount } from '../common/interfaces';
import firebase from '../services/firebase';
import commonStyle from '../common/style';
import ChatItem from './chatItem';
import { useKeyboard } from '../common/hooks';
import Subscribe from './subscribe';
import ImageCrop from './imageCrop';
import '../../assets/style.css';

export interface IChatViewProps {
  sender: IUserAccount;
  receive: IUserAccount;
  lock: boolean;
}

export interface IChatViewState {
  message: string;
  messages: Message[];
  keyboardOffset: number;
}

export interface Message {
  message: string;
  timestamp: Date;
  status: string;
  senderId: string | undefined;
  receiveId: string | undefined;
  type: string;
  ref: string;
  key: string;
}

const styles = StyleSheet.create({
  root: {
    height: '98%',
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    overflow: 'hidden',
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    borderBottomRightRadius: 15,
    borderTopRightRadius: 15,
  },
  body: {
    flex: 1,
    backgroundColor: '#EFEDEB',
  },
  header: {
    height: 40,
    justifyContent: 'center',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    borderTopRightRadius: 15,
    backgroundColor: '#EA0554',
  },
  headerText: {
    fontSize: 18,
    paddingRight: 5,
  },
  footer: {
    minHeight: 55,
    maxHeight: 80,
    justifyContent: 'center',
    backgroundColor: '#EFEDEB',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  inputView: {
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sendView: {
    flex: 0.2,
    maxWidth: 70,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  sendButton: {
    width: 40,
    height: 40,
    borderRadius: 50,
    elevation: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#05A4FF',
  },
  icon: {
    paddingLeft: 5,
    // elevation: 10,
    // shadowColor: '#000',
    // shadowOffset: {
    //   width: 0,
    //   height: 5,
    // },
    // shadowOpacity: 0.34,
    // shadowRadius: 6.27,
    transform: [{ rotate: '180deg' }],
  },
  inputMsgView: {
    backgroundColor: '#FFFFFF',
    width: '95%',
    overflow: 'hidden',
    borderRadius: 50,
    elevation: 2,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    opacity: 0.9,
    marginBottom: 2,
    paddingLeft: 14,
    paddingVertical: 4,
    paddingRight: 12,
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    color: '#545454',
    textAlign: 'right',
    outline: 'none',
  },
  fileButton: {
    paddingRight: 11,
  },
  chat: {
    flex: 1,
    paddingTop: 15,
    backgroundColor: '#EFEDEB',
  },
  fileView: {
    position: 'absolute',
    backgroundColor: '#F9F9F8',
    width: '90%',
    height: 80,
    top: -80,
    borderRadius: 15,
    elevation: 2,
  },
});

class ChatView extends Component<IChatViewProps, IChatViewState | any> {
  flatListRef: any;

  subscribe: any;

  keyboardDidHideListener: any;

  keyboardDidShowListener: any;

  inputRef: any;

  constructor(props: IChatViewProps) {
    super(props);
    this.state = {
      message: '',
      messages: [],
      currImage: '',
      showCrop: false,
      keyboardOffset: 0,
    };
    this.subscribe = null;
    this.flatListRef = React.createRef();
    this.inputRef = React.createRef();
  }

  async componentDidMount() {
    this.keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      this._keyboardDidShow.bind(this),
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      this._keyboardDidHide.bind(this),
    );
    if (Platform.OS !== 'web') {
      const {
        status: cameraStatus,
      } = await ImagePicker.requestCameraPermissionsAsync();
      const { status } = await ImagePicker.requestCameraRollPermissionsAsync();
    }

    const { sender, receive } = this.props;
    this.subscribe = firebase
      .firestore()
      .collection('Messages')
      .where('senderId', 'in', [sender.uid, receive.uid])
      .onSnapshot((querySnapshot: any) => this.onSnapshot(querySnapshot));
    setTimeout(() => {
      if (this.flatListRef && this.flatListRef!.scrollToEnd) {
        (this.flatListRef as any).scrollToEnd({ animated: true });
      }
    }, 2500);
  }

  componentDidUpdate(nextProps: IChatViewProps, nextState: IChatViewState) {
    const { sender, receive } = this.props;

    if (nextProps!.receive !== receive || nextProps!.sender !== sender) {
      this.subscribe = firebase
        .firestore()
        .collection('Messages')
        .where('senderId', 'in', [
          nextProps!.sender.uid,
          nextProps!.receive.uid,
        ])
        .onSnapshot((querySnapshot: any) => this.onSnapshot(querySnapshot));
    }
    if (nextState.message !== this.state.messages && this.flatListRef) {
      setTimeout(() => {
        if (this.flatListRef && this.flatListRef!.scrollToEnd) {
          (this.flatListRef as any).scrollToEnd({ animated: true });
        }
      }, 50);
    }
  }

  componentWillUnmount() {
    this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener.remove();
    return this.subscribe();
  }

  async onCamera() {
    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      aspect: [2.5, 2],
      quality: 0.2,

      allowsEditing: true,
    });
    if (!result.cancelled) {
      this.setState({ currImage: result.uri, showCrop: true });
      // this.onNewImage(result);
    }
  }

  async onLibrary() {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      aspect: [2.5, 2],

      allowsEditing: true,
      quality: 0.2,
    });
    if (!result.cancelled) {
      this.setState({ currImage: result.uri, showCrop: true });
      // this.onNewImage(result);
    }
  }

  onSnapshot(querySnapshot: any) {
    let messages: Message[] = [];
    querySnapshot.forEach((documentSnapshot: any) => {
      const data = documentSnapshot.data();
      messages.push({
        ...data,
        timestamp: data?.timestamp?.toDate
          ? data.timestamp.toDate()
          : new Date(Date.now()),
        key: documentSnapshot.id,
      });
    });
    messages = this.sortByTimestamp(this.filterMessages(messages));
    this.getImagesMessages(messages).then((message) => {
      this.setState({
        messages: message,
      });
    });
  }

  onNewImage({ uri }: { uri: string }) {
    const { sender, receive } = this.props;
    if (isEmpty(uri)) return;
    const messageBody = {
      message: '📷',
      timestamp: new Date(Date.now()),
      status: 'send',
      senderId: sender.uid,
      receiveId: receive.uid,
      type: 'image',
      ref: uri,
    };
    this.setState((prevState: any) => ({
      messages: [...prevState.messages, { ...messageBody, key: v4() }],
      message: '',
    }));
    this.inputRef.innerHTML = '';

    this.postImage(messageBody);
  }

  onNewMessage(message: string) {
    const { sender, receive } = this.props;
    if (isEmpty(message)) return;
    const messageBody = {
      message,
      timestamp: new Date(Date.now()),
      status: 'send',
      senderId: sender.uid,
      receiveId: receive.uid,
      type: 'text',
      ref: '',
    };
    this.setState((prevState: any) => ({
      messages: [...prevState.messages, { ...messageBody, key: v4() }],
      message: '',
    }));
    this.inputRef.innerHTML = '';

    this.postMessage(messageBody);
  }

  async getImagesMessages(messages: Message[]) {
    return Promise.all(
      messages.map(async (message) => {
        if (message.type === 'image') {
          const messageRef = message.ref
            .split('/')
            .filter((val) => !isEmpty(val));
          const fileName = messageRef.pop();

          const imgRef = await firebase
            .storage()
            .ref(messageRef.join('/'))
            .child(fileName)
            .getDownloadURL();

          return { ...message, ref: imgRef };
        }
        return message;
      }),
    );
  }

  _keyboardDidShow(event: any) {
    this.setState({
      keyboardOffset: event.endCoordinates.height,
    });
  }

  _keyboardDidHide() {
    this.setState({
      keyboardOffset: 0,
    });
  }

  sortByTimestamp(messages: Message[]) {
    return messages.sort((x: any, y: any) => {
      return x.timestamp - y.timestamp;
    });
  }

  filterMessages(messages: Message[]) {
    const { sender, receive } = this.props;
    return messages.filter(
      (msg) => msg.receiveId === sender.uid || msg.receiveId === receive.uid,
    );
  }

  async postImage(message: Message) {
    const url = Platform.OS === 'ios' ? message.ref : message.ref;
    // const _image = await ImageManipulator.manipulateAsync(url, [
    //   // { resize: { width: 640, height: 480 } },
    // ]);
    const response = await fetch(url);
    const blob = await response.blob();
    const fileName = v4();
    const ref = await firebase
      .storage()
      .ref(`/${message.senderId}/images`)
      .child(fileName);
    await ref.put(blob);
    const db = firebase.firestore();
    return db.collection('Messages').add({
      ...message,
      ref: `/${message.senderId}/images/${fileName}`,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  postMessage(message: Message) {
    const db = firebase.firestore();
    return db.collection('Messages').add({
      ...message,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  updateMessageRead(key: string) {
    const db = firebase.firestore();
    return db.collection('Messages').doc(key).set(
      {
        status: 'read',
      },
      { merge: true },
    );
  }

  render() {
    const { sender, receive, lock } = this.props;

    return (
      <>
        <ImageCrop
          visible={this.state.showCrop as any}
          setVisible={() => {
            this.setState({ currImage: '', showCrop: false });
          }}
          onCrop={(url) => {
            this.setState({ currImage: '', showCrop: false });
            this.onNewImage({ uri: url });
          }}
          image={this.state.currImage as any}
        />
        <SafeAreaInsetsContext.Consumer>
          {(insets) => (
            <View
              style={[
                styles.root,
                {
                  height:
                    Dimensions.get('window').height -
                      170 -
                      this.state.keyboardOffset -
                      insets?.bottom || 0,
                },
              ]}>
              {!lock ? (
                <></>
              ) : (
                <Subscribe
                  userId={this.props.sender.uid || ''}
                  receive={this.props.receive.name || ''}
                />
              )}

              <View style={styles.header}>
                <AntDesign name="message1" size={22} color="#F3F3F3" />
              </View>
              <FlatList
                style={styles.chat}
                data={this.state.messages}
                ref={(ref) => {
                  this.flatListRef = ref;
                }}
                keyExtractor={(item) => String(item.key)}
                renderItem={({ item }) => {
                  return (
                    <ChatItem
                      onPress={() => {
                        Keyboard.dismiss();
                      }}
                      messageRead={(key: string) => {
                        this.updateMessageRead(key);
                      }}
                      item={item}
                      user={
                        item.senderId === sender.uid &&
                        item.receiveId === receive.uid
                          ? sender
                          : receive
                      }
                      mode={
                        item.senderId === sender.uid &&
                        item.receiveId === receive.uid
                          ? 'send'
                          : 'receive'
                      }
                    />
                  );
                }}
              />

              <View
                style={[
                  styles.footer,
                  { paddingRight: insets?.right, borderBottomRightRadius: 15 },
                ]}>
                <View style={styles.inputView}>
                  <View style={styles.inputMsgView}>
                    <span
                      ref={(ref) => {
                        this.inputRef = ref;
                      }}
                      style={{
                        display: 'block',
                        width: '100%',
                        overflow: 'hidden',
                        overflowY: 'auto',
                        resize: 'both',
                        minHeight: '20px',
                        lineHeight: '20px',
                        direction: 'rtl',
                        maxHeight: '70px',
                        color: 'black',
                        outline: 'none',
                        fontFamily: 'Heebo-Regular',
                      }}
                      placeholder="הקלד הודעה"
                      className="textarea"
                      role="textbox"
                      onFocus={() => {
                        setTimeout(() => {
                          if (
                            this.flatListRef &&
                            this.flatListRef!.scrollToEnd
                          ) {
                            (this.flatListRef as any).scrollToEnd({
                              animated: true,
                            });
                          }
                        }, 50);
                      }}
                      onKeyPress={() => {
                        setTimeout(() => {
                          if (
                            this.flatListRef &&
                            this.flatListRef!.scrollToEnd
                          ) {
                            (this.flatListRef as any).scrollToEnd({
                              animated: true,
                            });
                          }
                        }, 50);
                      }}
                      onInput={(e: any) => {
                        console.log(
                          e.target.innerHTML.replace(/<[^>]*>/g, ' '),
                        );

                        this.setState({
                          message: e.target.innerHTML
                            .replace(/<[^>]*>/g, ' ')
                            .replace(/(&nbsp;|<br>|<br \/>)/g, ''),
                        });
                      }}
                      contentEditable>
                      {/* {this.state.message} */}
                    </span>
                    {/* <TextInput
                      multiline
                      placeholderTextColor="#8D8D8D"
                      placeholder="הקלד הודעה"
                      value={this.state.message}
                      onFocus={() => {
                        setTimeout(() => {
                          if (
                            this.flatListRef &&
                            this.flatListRef!.scrollToEnd
                          ) {
                            (this.flatListRef as any).scrollToEnd({
                              animated: true,
                            });
                          }
                        }, 50);
                      }}
                      onKeyPress={() => {
                        setTimeout(() => {
                          if (
                            this.flatListRef &&
                            this.flatListRef!.scrollToEnd
                          ) {
                            (this.flatListRef as any).scrollToEnd({
                              animated: true,
                            });
                          }
                        }, 50);
                      }}
                      onChangeText={(text) => {
                        this.setState({ message: text });
                      }}
                      style={[commonStyle.text, styles.input]}
                    /> */}
                    <TouchableOpacity
                      onPress={() => this.onLibrary()}
                      style={styles.fileButton}>
                      <Entypo name="attachment" size={20} color="#9E9E9E" />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => this.onCamera()}
                      style={styles.fileButton}>
                      <Entypo name="camera" size={20} color="#9E9E9E" />
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={styles.sendView}>
                  <TouchableOpacity
                    onPress={async () => {
                      this.onNewMessage(this.state.message);
                    }}
                    style={styles.sendButton}>
                    <MaterialIcons
                      name="send"
                      size={24}
                      style={styles.icon}
                      color="#E3F0EF"
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          )}
        </SafeAreaInsetsContext.Consumer>
      </>
    );
  }
}

export default ChatView;
