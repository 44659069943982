/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import * as ImageManipulator from 'expo-image-manipulator';
import { Platform } from 'react-native';
import { v4 } from 'react-native-uuid';
import firebase from './firebase';

export const uploadImagesByUser = async (
  imageUrls: string[],
  path: string,
  userId: string,
) => {
  return Promise.all(
    imageUrls.map(async (url) => {
      const _url = Platform.OS === 'ios' ? url : url;
      const minimizeImage = await ImageManipulator.manipulateAsync(_url, [
        { resize: { width: 640, height: 480 } },
      ]);
      const response = await fetch(minimizeImage.uri);
      const blob = await response.blob();
      const ref = await firebase
        .storage()
        .ref(`/${userId}/${path}`)
        .child(v4());
      const imgUrl = await ref
        .put(blob)
        .then((res: any) => res.ref.getDownloadURL());

      return imgUrl;
    }),
  );
};

export const deleteImagesByUrl = async (imageUrls: string[]) => {
  return Promise.all(
    imageUrls.map(async (url) => {
      const ref = firebase.storage().refFromURL(url);
      return ref.delete();
    }),
  );
};

export const updateMuteList = async (userId: string, muteList: string[]) => {
  return firebase.firestore().collection('Users').doc(userId).set(
    {
      mute: muteList,
    },
    { merge: true },
  );
};
