/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import {
  StyleSheet,
  View,
  Image,
  TouchableOpacity,
  Text,
  Vibration,
} from 'react-native';
import { Octicons, AntDesign } from '@expo/vector-icons';
import { isEmpty, uniq } from 'lodash';
import { Skeleton } from 'react-native-magnus';
import { Asset } from 'expo-asset';
import firebase from '../services/firebase';
import commonStyle from '../common/style';
import SideButton from './sideButton';
import { Message } from './chatView';
import { getAlbumImages, getProfileImage } from '../context/account';
import { IUserAccount } from '../common/interfaces';
import { updateMuteList } from '../services/firebase.services';
import { useDidUpdateEffect } from '../common/hooks';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: 95,
    borderBottomColor: '#FD1A69',
    borderBottomWidth: 1.5,
    flexDirection: 'row-reverse',
  },
  innerView: {
    width: '100%',
    flex: 1,
    flexDirection: 'row-reverse',
  },
  imageContainer: {
    width: 85,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContainerSkeleton: {
    width: '98%',
    height: 18,
    flexDirection: 'row-reverse',
    alignItems: 'center',
    marginBottom: 5,
  },
  infoContainer: {
    flex: 1,
    paddingTop: 5,
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  headerContainer: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    marginBottom: 0.5,
  },
  headerSubContainer: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    width: '98%',
    overflow: 'hidden',
    paddingRight: 5,
    justifyContent: 'space-between',
    height: 15,
    marginBottom: 1,
  },
  borderBottom: {
    borderBottomColor: '#E2D4C1',
    borderBottomWidth: 1,
  },
  optionsContainer: {
    width: 25,
  },
  image: {
    width: 75,
    height: 75,
    borderRadius: 8,
  },
  headerText: {
    color: '#393838',
    textAlign: 'right',
    alignSelf: 'center',
    alignItems: 'center',
    padding: 0,
    fontSize: 15,
  },
  subText: {
    color: '#393838',
    marginTop: -5,
    padding: 0,
    fontSize: 10.5,
    paddingHorizontal: 1.5,
  },
  msgText: {
    color: '#313131',
    lineHeight: 15,
    paddingTop: 6,
    flex: 1,
    fontSize: 14,
    textAlign: 'right',
  },
  optionContainer: {
    flex: 1,
  },
  optionContainerSkeleton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    backgroundColor: '#393838',
    width: 1.5,
    marginHorizontal: 4,
    height: '60%',
  },
  msgSkeleton: {
    width: '98%',
    flex: 0.85,
    marginTop: 3,
  },
  msgView: {
    flex: 1,
    width: '100%',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  badge: {
    width: 16,
    height: 16,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    backgroundColor: '#F10356',
    position: 'absolute',
    top: 3,
    right: 1,
    elevation: 6,
  },
  textBadge: {
    fontSize: 9,
  },
});

export interface IInboxItemProps {
  message: Message;
  accountId: any;
  navigation: any;
  isLoading: boolean;
  newMessages: number;
  onDelete: (message: Message) => void;
  account: IUserAccount;
}

const InboxItem = ({
  message,
  accountId,
  navigation,
  isLoading,
  onDelete,
  account,
  newMessages,
}: IInboxItemProps) => {
  const [mute, setMute] = useState(false);
  const [unReadMsg, setUnReadMsg] = useState(newMessages);
  const [image, setImage] = useState('');
  const [user, setUser] = useState<IUserAccount | any>({});
  const [notFound, setNotFound] = useState<boolean>(false);

  useEffect(() => {
    if (!isEmpty(user.profile)) {
      Asset.loadAsync(user.profile[0])
        .then((assets) => {
          if (!isEmpty(assets)) {
            setImage(assets[0].localUri as any);
          }
        })
        .catch(() => {
          setImage(user.profile[0] as any);
        });
    }
  }, [user]);

  useEffect(() => {
    setUnReadMsg(newMessages);
  }, [message, newMessages]);

  useEffect(() => {
    if ((account.mute || []).includes(accountId)) {
      setMute(true);
    }
  }, [account, accountId]);
  // useDidUpdateEffect(() => {
  //   if (mute) {
  //     updateMuteList(
  //       account.uid as string,
  //       uniq([...(account.mute || []), accountId]),
  //     );
  //     Vibration.vibrate(20);
  //   } else if (!mute) {
  //     updateMuteList(
  //       account.uid as string,
  //       uniq((account.mute || []).filter((userId) => userId !== accountId)),
  //     );
  //   }
  // }, [mute, account, accountId]);

  const onMute = useCallback(
    (_mute: any) => {
      setMute(_mute);
      if (_mute) {
        updateMuteList(
          account.uid as string,
          uniq([...(account.mute || []), accountId]),
        );
        Vibration.vibrate(20);
      } else if (!_mute) {
        updateMuteList(
          account.uid as string,
          uniq((account.mute || []).filter((userId) => userId !== accountId)),
        );
      }
    },
    [account, accountId],
  );

  useEffect(() => {
    const db = firebase.firestore();
    const subscriber = db
      .collection('Users')
      .where('uid', '==', accountId || '')
      .limit(1)
      .onSnapshot((querySnapshot: any) => {
        const usersPromise: any[] = [];
        querySnapshot.forEach(async (documentSnapshot: any) => {
          const data = documentSnapshot.data();
          const profile = getProfileImage(data.uid, data.sex);
          const album = getAlbumImages(data.uid, data.sex);
          console.log({ y: data.name, x: data.birthday });

          usersPromise.push(
            Promise.all([profile, album]).then(([profileUrl, albumUrl]) => {
              return {
                ...data,
                birthday: data.birthday.toDate(),
                album: albumUrl,
                profile: profileUrl,
                key: documentSnapshot.id,
              };
            }),
          );
        });
        if (usersPromise.length === 0) {
          setNotFound(true);
        }

        Promise.all(usersPromise).then((_users) => {
          setUser({ ..._users[0] });
        });
      });
    return () => subscriber();
  }, [accountId]);

  const onPress = () => {
    navigation.navigate('Chat', {
      userId: accountId,
      user,
    });
  };
  if (notFound) {
    return <></>;
  }
  return (
    <View style={[styles.root]}>
      {!isEmpty(user) && !isLoading ? (
        <TouchableOpacity onPress={onPress} style={[styles.innerView]}>
          <View style={styles.imageContainer}>
            {isEmpty(image) ? (
              <Skeleton.Box w={75} h={75} bg="yellow100" />
            ) : (
              <>
                <Image
                  style={styles.image}
                  resizeMode="stretch"
                  source={{
                    uri: image,
                  }}
                />
                {unReadMsg ? (
                  <View style={styles.badge}>
                    <Text
                      numberOfLines={1}
                      style={[commonStyle.textBold, styles.textBadge]}>
                      {unReadMsg}
                    </Text>
                  </View>
                ) : (
                  <></>
                )}
              </>
            )}
          </View>
          <View style={styles.infoContainer}>
            <View style={styles.headerContainer}>
              {[
                user!.name!,
                new Date(Date.now()).getFullYear() -
                  (user.birthday as Date).getFullYear(),
                user.status,
              ].map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {index && index !== 3 ? (
                      <View style={styles.divider} />
                    ) : (
                      <></>
                    )}
                    <Text
                      numberOfLines={1}
                      style={[
                        commonStyle.textBold,
                        styles.headerText,
                        index === 0 ? { maxWidth: 66 } : {},
                      ]}>
                      {item}
                    </Text>
                  </React.Fragment>
                );
              })}
            </View>
            <View style={[styles.headerSubContainer, styles.borderBottom]}>
              {[
                `${user.length / 100} מ'`,
                user.body,
                user.relationship[0],
                user.interesting,
              ].map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Text style={[commonStyle.textBold, styles.subText]}>
                      {item}
                    </Text>
                  </React.Fragment>
                );
              })}
            </View>
            <View style={styles.msgView}>
              <Text
                numberOfLines={2}
                style={[commonStyle.text, styles.msgText]}>
                {message.message}
              </Text>
            </View>
          </View>
          <View style={styles.optionsContainer}>
            <View style={styles.optionContainer}>
              <SideButton
                selectedColor="#FFFFFF"
                selectedIcon={<Octicons name="mute" />}
                unSelectedColor="#ED708E"
                onChange={() => {
                  onMute(!mute);
                }}
                unSelectedIcon={<Octicons name="unmute" />}
                active={mute}
              />
            </View>
            <View style={styles.optionContainer}>
              <SideButton
                selectedColor="#FFFFFF"
                selectedIcon={<AntDesign name="delete" />}
                unSelectedColor="#3B4144"
                onChange={() => onDelete(message)}
                unSelectedIcon={<AntDesign name="delete" />}
                active={false}
              />
            </View>
          </View>
        </TouchableOpacity>
      ) : (
        <LoadingInboxItem />
      )}
    </View>
  );
};

const LoadingInboxItem = () => {
  return (
    <TouchableOpacity style={[styles.innerView]}>
      <View style={styles.imageContainer}>
        <Skeleton.Box w={75} h={75} bg="yellow100" />
      </View>
      <View style={styles.infoContainer}>
        <View style={styles.headerContainerSkeleton}>
          <Skeleton.Box w="100%" h="100%" bg="yellow100" />
        </View>
        <View style={styles.headerSubContainer}>
          <Skeleton.Box w="100%" h="100%" bg="yellow100" />
        </View>
        <View style={[styles.msgSkeleton]}>
          <Skeleton.Box w="100%" h="100%" bg="yellow100" />
        </View>
      </View>
      <View style={styles.optionsContainer}>
        <View style={styles.optionContainerSkeleton}>
          <Skeleton.Circle w={23} h={23} bg="yellow100" />
        </View>
        <View style={styles.optionContainerSkeleton}>
          <Skeleton.Circle w={23} h={23} bg="yellow100" />
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default InboxItem;
