/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { isEmpty } from 'lodash';
import firebase from '../../../services/firebase';

export default async (
  values: { validation?: string } = {},
  context: any,
  setContext: (key: any, val: any) => void,
  next: (end: boolean) => void,
) => {
  if (!context.emailLogin) {
    if (isEmpty(values.validation)) {
      return { status: 'error', title: 'שגיאה', msg: 'לא הוכנס קוד אימות' };
    }
    if ((values.validation as string).length < 6) {
      return {
        status: 'error',
        title: 'שגיאה',
        msg: 'הוכנס קוד אימות לא תקין',
      };
    }

    const firebaseRes = await confirmCode(
      context.verificationId as string,
      values.validation as string,
    );
    if (!firebaseRes) {
      return {
        status: 'error',
        title: 'שגיאה',
        msg: 'הוכנס קוד אימות לא תקין',
      };
    }
    if (await userExist(firebaseRes.user.uid)) {
      next(true);

      return { status: 'ok' };
    }
    setContext('user', firebaseRes);
    next(false);
    return { status: 'ok' };
  }
  if (await userExist(context.emailResponse.user.uid)) {
    next(true);

    return { status: 'ok' };
  }
  setContext('user', context.emailResponse);
  next(false);
  return { status: 'ok' };
};

const confirmCode = async (verificationId: string, code: string) => {
  const credential = firebase.auth.PhoneAuthProvider.credential(
    verificationId,
    code,
  );

  return firebase
    .auth()
    .signInWithCredential(credential)
    .then((result: any) => {
      return result;
    })
    .catch((e) => {
      return false;
    });
};

const userExist = async (id: string) => {
  const db = firebase.firestore();

  return db
    .collection('Users')
    .doc(id)
    .get()
    .then((docSnapshot: any) => {
      if (!docSnapshot.exists) {
        return false;
      }
      const data = docSnapshot.data();
      if (data.uid) {
        return true;
      }
      return false;
    });
};
