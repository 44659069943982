import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Image,
  Text,
  Linking,
  ScrollView,
} from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import Background from '../../components/background';
import commonStyle from '../../common/style';
import logo from '../../../assets/logo.png';

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
  },
  header: {
    flex: 0.25,
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingHorizontal: 2,
    width: '100%',
  },
  logo: {
    flex: 0.6,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    width: '100%',
    paddingTop: 20,
    paddingHorizontal: 15,
  },
  footer: {
    flex: 0.2,
    paddingTop: 10,
    paddingBottom: 20,
    width: '100%',
  },
  logoImage: {
    width: 260,
    height: 260,
  },
  text: {
    textAlign: 'right',
    fontSize: 16,
    paddingTop: 5,
  },
  roundLabelText: {
    fontSize: 18,
    color: '#171717',
  },
  roundLabel: {
    backgroundColor: '#DEDEDE',
    padding: 6,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 2,
    width: '35%',
    alignSelf: 'center',
  },
});

export default ({ navigation }: any) => {
  return (
    <Background type="light">
      <View style={styles.root}>
        <View style={styles.header}>
          <TouchableOpacity
            onPress={() => {
              navigation.goBack();
            }}>
            <MaterialIcons name="navigate-next" size={45} color="#E1E3E3" />
          </TouchableOpacity>
        </View>
        <View style={styles.logo}>
          <Image style={styles.logoImage} source={logo} />
        </View>

        <ScrollView style={styles.content}>
          <Text style={[commonStyle.text, styles.text]}>כשיהלום פוגש כתר.</Text>
          <Text style={[commonStyle.text, styles.text]}>
            יהלום בודד אינו אותו היהלום כאשר הוא משובץ בטבעת או בשרשרת יוקרתית.
          </Text>
          <Text style={[commonStyle.text, styles.text]}>
            בעולם בו אישה היא יהלום, אתר Be My Baby מאפשר לכל יהלום לפגוש את
            התכשיט היוקרתי המתאים לו, או במילים אחרות, מאפשר לכל אישה או בחורה
            איכותית להתמזג עם גבר מרשים, אמיד ואדיב שישבץ אותה בעולמו היוקרתי
            והמפנק.
          </Text>
          <Text style={[commonStyle.text, styles.text]}>
            ב- Be My Baby תמצאו סביבה אינטימית, בטוחה, איכותית ורלוונטית מאוד
            עבור עבור משתמשיו שנבחרים בתהליך מיון קפדני.
          </Text>
        </ScrollView>
        <View style={styles.footer}>
          <TouchableOpacity
            onPress={() => {
              Linking.openURL('mailto:bar.dating.app@gmail.com');
            }}
            style={styles.roundLabel}>
            <Text style={[commonStyle.textBold, styles.roundLabelText]}>
              צור קשר
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Background>
  );
};
