/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import * as Location from 'expo-location';
import Constants from 'expo-constants';
// import * as Notifications from 'expo-notifications';
import * as Permissions from 'expo-permissions';
// import { Toast } from 'popup-ui';
import { Platform, Image } from 'react-native';
import { AuthContext } from './authentication';
import firebase from '../services/firebase';
import { IUserAccount } from '../common/interfaces';
import { FilterContext } from './filters';
import MenImage from '../../assets/men.jpg';
import WomenImage from '../../assets/women.jpg';

// Notifications.setNotificationHandler({
//   handleNotification: async () => ({
//     shouldShowAlert: true,
//     shouldPlaySound: false,
//     shouldSetBadge: false,
//   }),
// });

export interface IAccountContext {
  account?: IUserAccount;
  isLoading?: boolean;
}

export const AccountContext = React.createContext<IAccountContext>({});

export interface IAccountContextProps {
  children: any;
}

export const AccountProvider = ({ children }: IAccountContextProps) => {
  const { userInfo, autoLogin, logout } = React.useContext(AuthContext);
  const { setFilters, filters } = React.useContext(FilterContext);

  const [account, setAccount] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const notificationListener = useRef<any>();
  const responseListener = useRef<any>();
  useEffect(() => {
    if (account.interesting && filters.interesting !== account.interesting) {
      setFilters({ ...filters, interesting: account.interesting });
    }
  }, [filters, account]);
  useEffect(() => {
    registerForPushNotificationsAsync().then(async (token) => {
      if (userInfo.uid && autoLogin && token) {
        await updateNotification(token, userInfo.uid);
      }
    });

    // notificationListener.current = Notifications.addNotificationReceivedListener(
    //   () => {},
    // );

    // responseListener.current = Notifications.addNotificationResponseReceivedListener(
    //   () => {},
    // );

    return () => {
      // Notifications.removeNotificationSubscription(notificationListener as any);
      // Notifications.removeNotificationSubscription(responseListener as any);
    };
  }, [userInfo, autoLogin]);
  useEffect(() => {
    (async () => {
      await Location.requestPermissionsAsync();
      if (userInfo.uid && autoLogin) {
        const location = await Location.getCurrentPositionAsync({});
        await updateLocation(location.coords, userInfo.uid);
      }
    })();
  }, [userInfo, autoLogin]);

  useEffect(() => {
    if (userInfo.uid && autoLogin) {
      const subscriber = firebase
        .firestore()
        .collection('Users')
        .where('uid', '==', userInfo.uid || '')
        .onSnapshot(onUserInfoChange);
      return subscriber;
    }
    return () => {};
  }, [userInfo, autoLogin, logout]);

  const onUserInfoChange = async (snapShotInfos: any) => {
    const accountsPromise: any[] = [];

    snapShotInfos.forEach(
      async (documentSnapshot: { data: () => any; id: any }) => {
        const data = documentSnapshot.data();
        const profile = getProfileImage(data.uid, data.sex);
        const album = getAlbumImages(data.uid, data.sex);
        accountsPromise.push(
          Promise.all([profile, album]).then(([profileUrl, albumUrl]) => {
            return {
              ...data,
              expiredIn: data.expiredIn
                ? new Date(data.expiredIn.toDate())
                : null,
              birthday: new Date(data.birthday.toDate()),
              album: albumUrl,
              profile: profileUrl,
              key: documentSnapshot.id,
            };
          }),
        );
      },
    );
    const accounts = await Promise.all(accountsPromise);
    if (!isEmpty(accounts)) {
      setAccount(accounts[0]);
    } else {
      logout();
    }
    setIsLoading(false);
  };
  return (
    <AccountContext.Provider value={{ account, isLoading }}>
      {children}
    </AccountContext.Provider>
  );
};

export const getAlbumImages = async (id: string, gender: string) => {
  return firebase
    .storage()
    .ref(`/${id}/album`)
    .listAll()
    .then((res: any) => {
      const imageRefs: any[] = [];
      if (!res.items.length) {
        return [gender === 'גבר' ? MenImage : WomenImage];
      }
      res.items.forEach(async (itemRef: any) => {
        imageRefs.push(
          itemRef.getDownloadURL().then((url: string) => {
            return url;
          }),
        );
      });

      return Promise.all(imageRefs);
    })
    .catch((error: any) => {
      return [gender === 'גבר' ? MenImage : WomenImage];
    });
};

export const getProfileImage = async (id: string, gender: string) => {
  return firebase
    .storage()
    .ref(`/${id}/profile`)
    .listAll()
    .then((res: any) => {
      if (!res.items.length) {
        return [gender === 'גבר' ? MenImage : WomenImage];
      }
      const imageRefs: any[] = [];
      res.items.forEach(async (itemRef: any) => {
        imageRefs.push(
          itemRef.getDownloadURL().then((url: string) => {
            return url;
          }),
        );
      });

      return Promise.all(imageRefs);
    })
    .catch((error: any) => {
      return [gender === 'גבר' ? MenImage : WomenImage];
    });
};

const updateLocation = (coords: any, id: string) => {
  const db = firebase.firestore();
  return db.collection('Users').doc(id).set(
    {
      coords,
    },
    { merge: true },
  );
};

const updateNotification = (token: string, id: string) => {
  const db = firebase.firestore();
  return db.collection('Users').doc(id).set(
    {
      notificationToken: token,
    },
    { merge: true },
  );
};

const registerForPushNotificationsAsync = async () => {
  let token = '';
  if (Constants.isDevice) {
    const { status: existingStatus } = await Permissions.getAsync(
      Permissions.NOTIFICATIONS,
    );
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      // Toast.show({
      //   title: 'שגיאה במתן הרשאות',
      //   text: 'לא ניתנה  הרשאה הצגת התראות',
      //   color: '#FF005A',
      // });
      return;
    }
    // token = (await Notifications.getExpoPushTokenAsync()).data;
  } else {
    // Toast.show({
    //   title: 'שגיאה במתן הרשאות',
    //   text: 'לא ניתנה  הרשאה הצגת התראות',
    //   color: '#FF005A',
    // });
  }

  if (Platform.OS === 'android') {
    // Notifications.setNotificationChannelAsync('default', {
    //   name: 'default',
    //   importance: Notifications.AndroidImportance.MAX,
    //   vibrationPattern: [0, 250, 250, 250],
    //   lightColor: '#FF005A',
    // });
  }

  return token;
};
