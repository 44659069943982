/* eslint-disable react/no-array-index-key */
/* eslint-disable react-native/no-inline-styles */
import React, { useContext, useEffect, useState } from 'react';
import {
  StyleSheet,
  View,
  Modal,
  Text,
  FlatList,
  TouchableOpacity,
  Platform,
} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import commonStyle from '../../common/style';
import CheckBoxGroup from '../../components/checkboxGroup';
import RadioGroup from '../../components/radioGroup';
import Slider from '../../components/slider';
import { FilterContext } from '../../context/filters';
import ModalWeb from 'modal-react-native-web';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  modal: {
    flex: 1,
    width: '100%',
  },
  innerView: {
    width: '90%',
    height: 560,
    maxWidth: 400,
    borderRadius: 10,
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
  },
  body: {
    flex: 0.85,
    width: '90%',
  },
  buttonView: {
    position: 'absolute',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    bottom: -30,
  },
  button: {
    height: 50,
    width: 140,
    borderRadius: 50,
    elevation: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
    backgroundColor: '#F40257',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textButton: {
    fontSize: 26,
  },
  innerButton: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  close: {
    flex: 0.08,
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '92%',
  },
  header: {
    flex: 0.045,
    paddingBottom: 30,
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerText: {
    fontSize: 18,
    color: '#005167',
  },
  content: {
    flex: 1,
    alignSelf: 'center',
    width: '100%',
  },
  option: {
    width: '100%',
    flexDirection: 'row-reverse',
    margin: 1,
    paddingTop: 15,
    paddingBottom: 2,
  },
  labelText: {
    width: 70,
    fontSize: 16,
    textAlign: 'right',
    color: '#1A1A1A',
  },
  inputView: {
    flex: 1,
    width: '100%',
  },
});

const options = [
  {
    key: 'age',
    label: 'גיל',
    input: <Slider step={1} min={18} max={99} width={210} labelText={String} />,
  },
  {
    key: 'length',
    label: 'גובה',
    input: (
      <Slider
        step={2}
        min={110}
        max={240}
        width={210}
        labelText={(val) => {
          return `${((val as unknown) as number) / 100} מ'`;
        }}
      />
    ),
  },
  {
    key: 'body',
    label: 'מבנה גוף',
    input: (
      <CheckBoxGroup
        style={{
          backgroundColor: '#E2DACF',
          marginBottom: 5,
          fontSize: 13,
          fontColor: '#88827A',
          height: 35,
          width: 70,
          marginHorizontal: 2.5,
          activeColor: '#F1E9DE',
        }}
        options={['רזה', 'חטוב', 'עודף', 'מלא']}
      />
    ),
  },
  // {
  //   key: 'interesting',
  //   label: 'העדפה',
  //   input: (
  //     <RadioGroup
  //       style={{
  //         backgroundColor: '#E2DACF',
  //         marginBottom: 5,
  //         fontSize: 13,
  //         fontColor: '#88827A',
  //         height: 35,
  //         width: 70,
  //         marginHorizontal: 2.5,
  //         activeColor: '#F1E9DE',
  //       }}
  //       options={['גבר', 'אישה']}
  //     />
  //   ),
  // },
  // {
  //   key: 'status',
  //   label: 'סטטוס',
  //   input: (
  //     <CheckBoxGroup
  //       style={{
  //         backgroundColor: '#E2DACF',
  //         marginBottom: 5,
  //         fontSize: 13,
  //         fontColor: '#88827A',
  //         height: 35,
  //         width: 70,
  //         marginHorizontal: 2.5,
  //         activeColor: '#F1E9DE',
  //       }}
  //       options={['רווק/ה', 'גרוש/ה', 'נשוי/אה', 'אלמנ/ה']}
  //     />
  //   ),
  // },
  {
    label: 'סוג קשר',
    key: 'relationship',
    input: (
      <CheckBoxGroup
        style={{
          backgroundColor: '#E2DACF',
          marginBottom: 5,
          fontSize: 13,
          fontColor: '#88827A',
          height: 35,
          width: 70,
          marginHorizontal: 2.5,
          activeColor: '#F1E9DE',
        }}
        options={['סידור', 'בילויים']}
      />
    ),
  },
];

export default ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: () => void;
}) => {
  const { filters: contextFilter, setFilters: contextSetFilters } = useContext(
    FilterContext,
  );
  const [filters, setFilters] = useState<any>({
    age: contextFilter.age,
    length: contextFilter.length,
    body: contextFilter.body,
    interesting: contextFilter.interesting,
    // status: contextFilter.status,
    relationship: contextFilter.relationship,
  });

  useEffect(() => {
    setFilters({
      age: contextFilter.age,
      length: contextFilter.length,
      body: contextFilter.body,
      interesting: contextFilter.interesting,
      // status: contextFilter.status,
      relationship: contextFilter.relationship,
    });
  }, [contextFilter]);

  const onClose = () => {
    setFilters({
      age: contextFilter.age,
      length: contextFilter.length,
      body: contextFilter.body,
      interesting: contextFilter.interesting,
      // status: contextFilter.status,
      relationship: contextFilter.relationship,
    });
    setVisible();
  };
  const onSave = () => {
    contextSetFilters(filters);
    setVisible();
  };
  if (Platform.OS === 'web') {
    return (
      <ModalWeb
        style={styles.modal}
        animationType="fade"
        transparent
        statusBarTranslucent
        onRequestClose={setVisible}
        visible={visible}>
        <View style={styles.root}>
          <View style={styles.innerView}>
            <View style={styles.close}>
              <TouchableOpacity onPress={onClose}>
                <FontAwesome name="close" size={26} color="#005167" />
              </TouchableOpacity>
            </View>
            <View style={styles.body}>
              <View style={styles.header}>
                <Text style={[commonStyle.textBold, styles.headerText]}>
                  חיפוש מותאם אישית
                </Text>
              </View>
              <FlatList
                data={options}
                style={styles.content}
                keyExtractor={(item, index) => String(index)}
                renderItem={({ item, index }) => {
                  return (
                    <View key={index} style={styles.option}>
                      <Text style={[commonStyle.textBold, styles.labelText]}>
                        {item.label}
                      </Text>
                      <View style={styles.inputView}>
                        {React.cloneElement(item.input, {
                          value: filters[item.key],
                          onValChange: (val: any) => {
                            setFilters({ ...filters, [item.key]: val });
                          },
                        })}
                      </View>
                    </View>
                  );
                }}
              />
            </View>
            <View style={styles.buttonView}>
              <View style={styles.button}>
                <TouchableOpacity onPress={onSave} style={styles.innerButton}>
                  <Text style={[commonStyle.text, styles.textButton]}>
                    חיפוש
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </ModalWeb>
    );
  }
  return (
    <Modal
      style={styles.modal}
      animationType="fade"
      transparent
      statusBarTranslucent
      onRequestClose={onClose}
      visible={visible}>
      <View style={styles.root}>
        <View style={styles.innerView}>
          <View style={styles.close}>
            <TouchableOpacity onPress={onClose}>
              <FontAwesome name="close" size={26} color="#005167" />
            </TouchableOpacity>
          </View>
          <View style={styles.body}>
            <View style={styles.header}>
              <Text style={[commonStyle.textBold, styles.headerText]}>
                חיפוש מותאם אישית
              </Text>
            </View>
            <FlatList
              data={options}
              style={styles.content}
              keyExtractor={(item, index) => String(index)}
              renderItem={({ item, index }) => {
                return (
                  <View key={index} style={styles.option}>
                    <Text style={[commonStyle.textBold, styles.labelText]}>
                      {item.label}
                    </Text>
                    <View style={styles.inputView}>
                      {React.cloneElement(item.input, {
                        value: filters[item.key],
                        onValChange: (val: any) => {
                          setFilters({ ...filters, [item.key]: val });
                        },
                      })}
                    </View>
                  </View>
                );
              }}
            />
          </View>
          <View style={styles.buttonView}>
            <View style={styles.button}>
              <TouchableOpacity onPress={onSave} style={styles.innerButton}>
                <Text style={[commonStyle.text, styles.textButton]}>חיפוש</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};
