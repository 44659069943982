/* eslint-disable import/prefer-default-export */
import { dark } from '@eva-design/eva';

export const myTheme = {
  ...dark,
  'color-primary-100': '#FFCECC',
  'color-primary-200': '#FF999E',
  'color-primary-300': '#FF667D',
  'color-primary-400': '#FF3F70',
  'color-primary-500': '#FF005A',
  'color-primary-600': '#DB0063',
  'color-primary-700': '#B70065',
  'color-primary-800': '#930060',
  'color-primary-900': '#7A005B',
  'color-primary-transparent-100': 'rgba(255, 0, 90, 0.08)',
  'color-primary-transparent-200': 'rgba(255, 0, 90, 0.16)',
  'color-primary-transparent-300': 'rgba(255, 0, 90, 0.24)',
  'color-primary-transparent-400': 'rgba(255, 0, 90, 0.32)',
  'color-primary-transparent-500': 'rgba(255, 0, 90, 0.4)',
  'color-primary-transparent-600': 'rgba(255, 0, 90, 0.48)',
  'color-success-100': '#FFCECC',
  'color-success-200': '#FF999E',
  'color-success-300': '#FF667D',
  'color-success-400': '#FF3F70',
  'color-success-500': '#FF005A',
  'color-success-600': '#DB0063',
  'color-success-700': '#B70065',
  'color-success-800': '#930060',
  'color-success-900': '#7A005B',
  'color-success-transparent-100': 'rgba(5, 164, 255, 0.08)',
  'color-success-transparent-200': 'rgba(5, 164, 255, 0.16)',
  'color-success-transparent-300': 'rgba(5, 164, 255, 0.24)',
  'color-success-transparent-400': 'rgba(5, 164, 255, 0.32)',
  'color-success-transparent-500': 'rgba(5, 164, 255, 0.4)',
  'color-success-transparent-600': 'rgba(5, 164, 255, 0.48)',
  'color-info-100': '#CBFEFE',
  'color-info-200': '#98F6FE',
  'color-info-300': '#65E7FE',
  'color-info-400': '#3FD3FD',
  'color-info-500': '#00B4FC',
  'color-info-600': '#008CD8',
  'color-info-700': '#0068B5',
  'color-info-800': '#004A92',
  'color-info-900': '#003578',
  'color-info-transparent-100': 'rgba(0, 180, 252, 0.08)',
  'color-info-transparent-200': 'rgba(0, 180, 252, 0.16)',
  'color-info-transparent-300': 'rgba(0, 180, 252, 0.24)',
  'color-info-transparent-400': 'rgba(0, 180, 252, 0.32)',
  'color-info-transparent-500': 'rgba(0, 180, 252, 0.4)',
  'color-info-transparent-600': 'rgba(0, 180, 252, 0.48)',
  'color-warning-100': '#FFF4CF',
  'color-warning-200': '#FFE59F',
  'color-warning-300': '#FFD36F',
  'color-warning-400': '#FFC14B',
  'color-warning-500': '#FFA30F',
  'color-warning-600': '#DB830A',
  'color-warning-700': '#B76607',
  'color-warning-800': '#934C04',
  'color-warning-900': '#7A3902',
  'color-warning-transparent-100': 'rgba(255, 163, 15, 0.08)',
  'color-warning-transparent-200': 'rgba(255, 163, 15, 0.16)',
  'color-warning-transparent-300': 'rgba(255, 163, 15, 0.24)',
  'color-warning-transparent-400': 'rgba(255, 163, 15, 0.32)',
  'color-warning-transparent-500': 'rgba(255, 163, 15, 0.4)',
  'color-warning-transparent-600': 'rgba(255, 163, 15, 0.48)',
  'color-danger-100': '#FEDAD7',
  'color-danger-200': '#FEAFB0',
  'color-danger-300': '#FE8695',
  'color-danger-400': '#FD6888',
  'color-danger-500': '#FC3775',
  'color-danger-600': '#D82871',
  'color-danger-700': '#B51B6A',
  'color-danger-800': '#92115F',
  'color-danger-900': '#780A58',
  'color-danger-transparent-100': 'rgba(252, 55, 117, 0.08)',
  'color-danger-transparent-200': 'rgba(252, 55, 117, 0.16)',
  'color-danger-transparent-300': 'rgba(252, 55, 117, 0.24)',
  'color-danger-transparent-400': 'rgba(252, 55, 117, 0.32)',
  'color-danger-transparent-500': 'rgba(252, 55, 117, 0.4)',
  'color-danger-transparent-600': 'rgba(252, 55, 117, 0.48)',
};
