/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/accessible-emoji */
import { Entypo } from '@expo/vector-icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, Text, Image, View } from 'react-native';
import { IUserAccount } from '../common/interfaces';
import commonStyle from '../common/style';
import ImagePreview from './imagePreview';

const styles = StyleSheet.create({
  root: {
    width: '95%',
    marginBottom: 20,
    flex: 1,
    alignSelf: 'center',
  },
  sender: {
    flexDirection: 'row-reverse',
  },
  receive: {
    flexDirection: 'row',
  },
  avatarView: {
    width: 55,
    justifyContent: 'center',
    alignItems: 'center',
  },
  msgView: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    position: 'relative',
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 50,
  },
  border: {
    backgroundColor: '#FFFFFF',
    flex: 0.95,
    zIndex: 1,
    elevation: 1,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  borderSender: {
    marginLeft: 15,
    borderBottomLeftRadius: 10,
  },
  borderReceive: {
    marginRight: 15,
    borderBottomRightRadius: 10,
  },
  text: {
    paddingTop: 10,
    paddingHorizontal: 8,
    justifyContent: 'center',
    fontSize: 13,
    lineHeight: 13.5,
    alignItems: 'center',
    color: '#5E5E5E',
    width: '100%',
    paddingBottom: 16,
  },
  textSender: {
    textAlign: 'right',
  },
  textReceive: {
    textAlign: 'right',
  },
  metadata: {
    position: 'absolute',
    zIndex: 5,
    bottom: 2,
    justifyContent: 'flex-start',
  },
  metaReceive: {
    right: 10,
    flexDirection: 'row-reverse',
  },
  metaSender: {
    left: 8,
    flexDirection: 'row',
  },
  metaReceiveImage: {
    right: 28,
    flexDirection: 'row-reverse',
  },
  metaSenderImage: {
    left: 28,
    flexDirection: 'row',
  },
  metadataText: {
    fontSize: 9,
    color: '#5E5E5E',
  },
  checksView: {
    width: 20,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  checkOneReceive: {
    position: 'absolute',
    right: 1,
    bottom: 1,
  },
  checkTwoReceive: {
    position: 'absolute',
    right: 6,
    bottom: 1,
  },
  checkOneSend: {
    position: 'absolute',
    left: 1,
    bottom: -11,
  },
  checkTwoSend: {
    position: 'absolute',
    left: 6,
    bottom: -11,
  },
  imageView: {
    width: '90%',
    height: 290,
    zIndex: 1,
    backgroundColor: 'rgb(239, 237, 235)',
    aspectRatio: 1,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  imageViewAvatar: {
    zIndex: 2,
    width: 50,
    height: 50,
    borderRadius: 50,
    overflow: 'hidden',
  },
});

export interface IChatItemProps {
  mode: 'send' | 'receive';
  user: IUserAccount;
  item: {
    message: string;
    timestamp: Date;
    status: 'send' | 'receive' | 'read';
    senderId: string;
    receiveId: string;
    type: 'text' | 'image';
    ref: string;
    key: string;
  };
  onPress: () => void;
  messageRead: (key: string) => void;
}

const ChatItem = ({
  mode,
  user,
  item,
  messageRead,
  onPress,
}: IChatItemProps) => {
  const sendMsg = mode === 'send';
  const [imagePreview, setImagePreview] = useState(false);
  useEffect(() => {
    if (!sendMsg && item.status !== 'read') {
      setTimeout(() => messageRead(item.key), 1000);
    }
  }, [item, mode, sendMsg]);
  return (
    <>
      {item.type === 'text' ? (
        <></>
      ) : (
        <ImagePreview
          visible={imagePreview}
          setVisible={() => {
            setImagePreview(false);
          }}
          image={item.ref}
        />
      )}
      <TouchableOpacity
        onPress={() => {
          onPress();
        }}
        style={[styles.root, sendMsg ? styles.sender : styles.receive]}>
        <View style={styles.avatarView}>
          <View style={styles.imageViewAvatar}>
            <Image
              style={styles.image}
              resizeMode="stretch"
              source={{
                uri: user!.profile[0] || '',
              }}
            />
          </View>
        </View>
        <View style={styles.msgView}>
          {item.type === 'text' ? (
            <View
              style={[
                styles.border,
                sendMsg ? styles.borderSender : styles.borderReceive,
              ]}>
              <Text
                selectable
                style={[
                  commonStyle.text,
                  styles.text,
                  sendMsg ? styles.textSender : styles.textReceive,
                ]}>
                {item.message}
              </Text>
              <Metadata item={item} sendMsg={sendMsg} />
            </View>
          ) : (
            <>
              <Image
                style={[
                  styles.imageView,
                  sendMsg ? styles.borderSender : styles.borderReceive,
                ]}
                resizeMode="center"
                source={{ uri: item.ref }}
              />
              <Metadata item={item} sendMsg={sendMsg} />
            </>
          )}
        </View>
      </TouchableOpacity>
    </>
  );
};

const Metadata = ({ sendMsg, item, color }: any) => (
  <View
    style={[
      styles.metadata,
      sendMsg
        ? item.type !== 'text'
          ? styles.metaSenderImage
          : styles.metaSender
        : item.type !== 'text'
        ? styles.metaReceiveImage
        : styles.metaReceive,
    ]}>
    <Text
      style={[
        commonStyle.text,
        styles.metadataText,
        color === 'white' ? { color: '#FFFFFF' } : {},
      ]}>
      {moment(item.timestamp).format('hh:mm A')}
    </Text>
    {sendMsg ? (
      <View style={styles.checksView}>
        {item.status === 'receive' || item.status === 'read' ? (
          <>
            <Entypo
              name="check"
              style={sendMsg ? styles.checkOneSend : styles.checkOneReceive}
              size={9}
              color={
                item.status === 'read'
                  ? '#05A4FF'
                  : color === 'white'
                  ? '#FFFFFF'
                  : '#898989'
              }
            />
            <Entypo
              style={sendMsg ? styles.checkTwoSend : styles.checkTwoReceive}
              name="check"
              size={9}
              color={
                item.status === 'read'
                  ? '#05A4FF'
                  : color === 'white'
                  ? '#FFFFFF'
                  : '#898989'
              }
            />
          </>
        ) : (
          <Entypo
            name="check"
            style={sendMsg ? styles.checkOneSend : styles.checkOneReceive}
            size={9}
            color={color === 'white' ? '#FFFFFF' : '#898989'}
          />
        )}
      </View>
    ) : (
      <></>
    )}
  </View>
);

export default ChatItem;
