/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState } from 'react';
// import MultiSlider, { LabelProps } from '@ptomasroos/react-native-multi-slider';
import { Platform, StyleSheet, View, Text } from 'react-native';
import RangeSlider from 'react-native-range-slider-expo';
import commonStyle from '../common/style';
import { IInputProps } from '../common/interfaces';
import { Range, getTrackBackground } from 'react-range';

// import RangeSlider from 'rn-range-slider';

const styles = StyleSheet.create({
  sliderWrapper: {
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    position: 'relative',
    display: 'flex',
  },
  viewContainer: {
    alignSelf: 'center',
    width: '90%',
    justifyContent: 'center',
  },
  textRange: {
    color: '#000000',
    fontSize: 16,
    textAlign: 'center',
    width: 60,
    fontWeight: '600',
    position: 'absolute',
    left: -30,
    top: -15,
  },
  textRange2: {
    color: '#000000',
    fontSize: 16,
    fontWeight: '600',
    textAlign: 'center',
    width: 60,
    position: 'absolute',
    right: -30,
    top: -15,
  },
  text: {
    color: '#88827A',
    fontSize: 12,
    width: 50,
    textAlign: 'center',
    position: 'absolute',
    top: -5,
  },
  activeText: {
    color: '#00A4FF',
  },
});

export interface ISlider extends IInputProps {
  max: number;
  min: number;
  step: number;
  width: number;
  labelText: (val: string) => string;
}

const Slider = ({
  value,
  onValChange,
  max,
  min,
  width,
  labelText,
  step,
}: ISlider) => {
  const [multiSliderValue, setMultiSliderValue] = useState(value);

  const multiSliderValuesChange = (values: any) => setMultiSliderValue(values);

  useEffect(() => {
    onValChange && onValChange(multiSliderValue as any);
  }, [multiSliderValue]);
  //
  console.log(multiSliderValue);

  // return <RangeSlider min={0} max={100} step={1} />;
  if (Platform.OS === 'web') {
    return (
      <View style={styles.viewContainer}>
        <View style={styles.sliderWrapper}>
          <Text style={[commonStyle.text, styles.textRange]}>
            {(multiSliderValue as any)[0]}
          </Text>
          <Range
            values={[
              multiSliderValue && (multiSliderValue as any)[0]
                ? (multiSliderValue as any)[0]
                : min,
              multiSliderValue && (multiSliderValue as any)[1]
                ? (multiSliderValue as any)[1]
                : max,
            ]}
            step={step}
            min={min}
            max={max}
            onChange={multiSliderValuesChange}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: '36px',
                  display: 'flex',
                  width: '100%',
                }}>
                <div
                  ref={props.ref}
                  style={{
                    height: '5px',
                    width: '100%',
                    borderRadius: '4px',
                    background: getTrackBackground({
                      values: multiSliderValue as any,
                      colors: ['#ccc', '#00A4FF', '#ccc'],
                      min: min,
                      max: max,
                      rtl: false,
                    }),
                    alignSelf: 'center',
                  }}>
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props, isDragged }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: '20px',
                  width: '20px',
                  borderRadius: '100%',
                  backgroundColor: '#00A4FF',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  transition: 'box-shadow 0.1s ease-in-out',
                  boxShadow: !isDragged
                    ? '0 0 0 4px rgb(204 233 255 / 0%)'
                    : '0 0 0 4px rgb(204 233 255 / 40%)',
                }}></div>
            )}
          />
          <Text style={[commonStyle.text, styles.textRange2]}>
            {(multiSliderValue as any)[1]}
          </Text>

          {/* <RangeSlider
            // min={min}
            // max={max}
            initialFromValue={11}
            min={5}
            max={25}
            // step={step}
            // fromValueOnChange={(value: any) => {
            //   multiSliderValuesChange([value, (multiSliderValue as any)[1]]);
            // }}
            // toValueOnChange={(value) => {}}
            // initialFromValue={(multiSliderValue as any)[0]}
            // initialToValue={(multiSliderValue as any)[1]}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            fromValueOnChange={(value: any) => setFromValue(value)}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            toValueOnChange={(value: any) => setToValue(value)}
          /> */}
        </View>
      </View>
    );
  }
  return (
    <View style={styles.viewContainer}>
      <View style={styles.sliderWrapper}>
        {/* <MultiSlider
          markerStyle={{
            ...Platform.select({
              ios: {
                height: 20,
                width: 20,
                shadowColor: '#000000',
                shadowOffset: {
                  width: 0,
                  height: 3,
                },
                shadowRadius: 1,
                shadowOpacity: 0.1,
              },
              android: {
                height: 18,
                width: 18,
                borderRadius: 50,
                backgroundColor: '#E2DACF',
              },
            }),
          }}
          pressedMarkerStyle={{
            ...Platform.select({
              android: {
                height: 20,
                width: 20,
                borderRadius: 20,
                backgroundColor: '#1792E8',
              },
            }),
          }}
          selectedStyle={{
            backgroundColor: '#1792E8',
          }}
          trackStyle={{
            backgroundColor: '#E2DACF',
          }}
          touchDimensions={{
            height: 40,
            width: 40,
            borderRadius: 20,
            slipDisplacement: 40,
          }}
          values={[
            multiSliderValue && (multiSliderValue as any)[0]
              ? (multiSliderValue as any)[0]
              : min,
            multiSliderValue && (multiSliderValue as any)[1]
              ? (multiSliderValue as any)[1]
              : max,
          ]}
          sliderLength={width}
          onValuesChange={multiSliderValuesChange}
          min={min}
          max={max}
          step={step}
          enableLabel
          allowOverlap={false}
          minMarkerOverlapDistance={10}
          customLabel={(props: LabelProps) => {
            const showLabel =
              props.twoMarkerLeftPosition - props.oneMarkerLeftPosition < 30;

            return (
              <>
                <Text
                  style={[
                    commonStyle.textBold,
                    styles.text,
                    !showLabel
                      ? {
                          left: props.twoMarkerLeftPosition - 24.5,
                        }
                      : {
                          right: -24.5,
                        },
                    !!props.twoMarkerPressed ? styles.activeText : {},
                  ]}>
                  {labelText(props.twoMarkerValue as string)}
                </Text>
                <Text
                  style={[
                    commonStyle.textBold,
                    styles.text,
                    {
                      left: !showLabel
                        ? props.oneMarkerLeftPosition - 24.5
                        : -24.5,
                    },
                    !!props.oneMarkerPressed ? styles.activeText : {},
                  ]}>
                  {labelText(props.oneMarkerValue as string)}
                </Text>
              </>
            );
          }}
        /> */}
      </View>
    </View>
  );
};

export default Slider;
