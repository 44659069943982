/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-native/no-inline-styles */
import React, { useRef, useState } from 'react';
import {
  Dimensions,
  FlatList,
  Keyboard,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { Entypo } from '@expo/vector-icons';
// import { Toast } from 'popup-ui';
import { isEmpty } from 'lodash';
import TextButton from '../../../components/textButton';
import commonStyle from '../../../common/style';
import InputText from '../../../components/inputText';
import { IStepScreens } from '../../../common/interfaces';
import RadioGroup from '../../../components/radioGroup';
import CheckBoxGroup from '../../../components/checkboxGroup';
import NumericInput from '../../../components/numericInput';
import DateInput from '../../../components/dateInput';
import termsDoc from '../../../../assets/terms.json';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 50,
  },
  titleText: {
    fontSize: 28,
    paddingBottom: 5,
  },
  containerPreferences: {
    width: '100%',
    alignItems: 'center',
    paddingTop: 15,
    flex: 1,
  },
  containerButton: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 15,
    flex: 0.2,
    width: '100%',
  },
  containerPreference: {
    width: '95%',
    paddingBottom: 15,
    flexDirection: 'row-reverse',
    justifyContent: 'center',
  },
  profileContainer: {
    width: '95%',
  },
  label: {
    fontSize: 18,
    width: 95,
    minWidth: 60,
    textAlign: 'right',
    flex: 0.15,
    color: '#CCCCCC',
  },
  inputContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  roundLabel: {
    backgroundColor: '#DEDEDE',
    padding: 6,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    alignSelf: 'center',
  },
  text: {
    fontSize: 18,
    color: '#171717',
  },
  infoContainer: {
    width: '90%',
    flex: 1,
    alignSelf: 'center',
    paddingTop: 15,
    paddingBottom: 15,
  },
  root: {
    width: '90%',
    paddingTop: 15,
    display: 'flex',
    overflow: 'hidden',
    height: '100%',
  },
  topicText: {
    fontSize: 15,
    paddingBottom: 2,
  },
  contentText: {
    fontSize: 15,
    paddingRight: 15,
    textAlign: 'right',
  },
  topic: {
    paddingBottom: 20,
  },
});

const isCloseToBottom = ({
  layoutMeasurement,
  contentOffset,
  contentSize,
}: any) => {
  const paddingToBottom = 30;
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  );
};

export default ({
  onNext = () => Promise.resolve({ status: 'ok' }),
  values,
  setValues,
  context,
}: IStepScreens) => {
  const [showNext, setShowNext] = useState(true);
  const scrollRef = useRef(null);
  const [scrollY, setScrollY] = useState(0);
  return (
    <View style={styles.container}>
      <Text style={[commonStyle.text, styles.titleText]}>
        תנאי שימוש ותקנון
      </Text>
      <View style={styles.root}>
        {/* <ScrollView style={{ flex: 0.5 }}>
          {termsDoc.map((item, index) => {
            return (
              <View style={styles.topic}>
                <Text style={[commonStyle.textBold, styles.topicText]}>
                  {item.showTopicIndex ? `${index}. ${item.topic}` : item.topic}
                </Text>
                {(item.content || []).map((text, key) => {
                  return (
                    <Text
                      key={key}
                      style={[commonStyle.text, styles.contentText]}>
                      {`${(item.index as any)[key]}${text}`}
                    </Text>
                  );
                })}
              </View>
            );
          })}
        </ScrollView> */}
        {/* <FlatList
          data={termsDoc}
          // scrollEventThrottle={400}
          ref={scrollRef}
          onScroll={({ nativeEvent }) => {
            // setScrollY(nativeEvent.contentOffset.y);
            // if (isCloseToBottom(nativeEvent)) {
            //   setShowNext(true);
            // } else {
            //   setShowNext(false);
            // }
          }}
          keyExtractor={(item, index) => String(index)}
          renderItem={({ item, index }) => {
            return (
              <View style={styles.topic}>
                <Text style={[commonStyle.textBold, styles.topicText]}>
                  {item.showTopicIndex ? `${index}. ${item.topic}` : item.topic}
                </Text>
                {(item.content || []).map((text, key) => {
                  return (
                    <Text
                      key={key}
                      style={[commonStyle.text, styles.contentText]}>
                      {`${(item.index as any)[key]}${text}`}
                    </Text>
                  );
                })}
              </View>
            );
          }}
        /> */}
        <View
          style={{
            height: '50vh',
          }}>
          <ScrollView>
            {termsDoc.map((item, index) => {
              return (
                <View style={styles.topic}>
                  <Text style={[commonStyle.textBold, styles.topicText]}>
                    {item.showTopicIndex
                      ? `${index}. ${item.topic}`
                      : item.topic}
                  </Text>
                  {(item.content || []).map((text, key) => {
                    return (
                      <Text
                        key={key}
                        style={[commonStyle.text, styles.contentText]}>
                        {`${(item.index as any)[key]}${text}`}
                      </Text>
                    );
                  })}
                </View>
              );
            })}
          </ScrollView>
        </View>
        <View style={[styles.containerButton]}>
          {showNext ? (
            <TextButton
              title="מאשר"
              onClick={async () => {
                Keyboard.dismiss();
                const toast = await onNext();
                if (toast?.status === 'error' || toast?.status === 'info') {
                  // Toast.show({
                  //   title: toast.title,
                  //   text: toast.msg,
                  //   color: '#FF005A',
                  // });
                }
              }}
              style={{
                width: 140,
                fontSize: 20,
                height: 6,
                background: '#05a4ff',
                color: '#1a1a1a',
                disable: false,
              }}
            />
          ) : (
            <TouchableOpacity
              onPress={() => {
                if (scrollRef.current) {
                  (scrollRef.current as any).scrollToOffset({
                    animated: true,
                    offset: scrollY + 200,
                  });
                }
              }}>
              <Entypo name="chevron-down" size={45} color="#ff005a" />
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  );
};
