/* eslint-disable react-native/no-inline-styles */
import React, { useContext, useEffect, useState } from 'react';
import {
  Dimensions,
  Image,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
} from 'react-native';
import { Entypo, Ionicons, MaterialIcons } from '@expo/vector-icons';
import { isEmpty } from 'lodash';
import { Asset } from 'expo-asset';
import { Skeleton } from 'react-native-magnus';
import * as ImageManipulator from 'expo-image-manipulator';
import { getDistance } from 'geolib';
import Background from '../../components/background';
import commonStyle from '../../common/style';
import Inbox from '../../components/inbox';
import ChatView from '../../components/chatView';
import Album from '../../components/album';
import { AccountContext } from '../../context/account';
import { IUserAccount } from '../../common/interfaces';
import ImagePreview from '../../components/imagePreview';
import firebase from '../../services/firebase';
import ErrorModal from '../../components/errorModal';
import BlockIcon from '../../../assets/block.png';

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
  },
  header: {
    height: 140,
  },
  body: {
    flex: 1,
    flexDirection: 'row-reverse',
  },
  headerTop: {
    flex: 0.9,
    backgroundColor: '#F3F3F3',
    justifyContent: 'flex-end',
    paddingRight: 140,
    alignItems: 'center',
  },
  headerBottom: {
    flex: 1,
    alignItems: 'flex-end',
    paddingRight: 105,
  },
  infoTextView: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    paddingBottom: 4,
    paddingLeft: 0,
    alignItems: 'center',
    alignSelf: 'flex-end',
    width: '100%',
    overflow: 'hidden',
  },
  divider: {
    backgroundColor: '#1A1A1A',
    width: 3,
    height: '55%',
    marginHorizontal: 8,
  },
  text: {
    color: '#1A1A1A',
    fontSize: 20,
  },
  imageView: {
    width: 110,
    height: 110,
    position: 'absolute',
    right: 15,
    backgroundColor: '#1A1A1A',
    top: 5,
    borderRadius: 20,
    elevation: 1,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 110,
    height: 110,
    borderRadius: 20,
  },
  homeButtonView: {
    width: 55,
    height: '100%',
    left: 0,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
  },
  homeButton: {
    width: 45,
    height: 45,
    borderRadius: 50,
    backgroundColor: '#F1E9DE',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoSubTextView: {
    width: '95%',
    height: 22,
    paddingLeft: 0,
    paddingRight: 35,
    alignItems: 'flex-start',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  aboutTextView: {
    width: '100%',
    flex: 1,
    alignItems: 'flex-start',
    paddingTop: 3.5,
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 30,
  },
  subInfo: {
    color: '#F0E8DD',
    fontSize: 17,
  },
  aboutText: {
    color: '#898989',
    textAlign: 'right',
    fontSize: 16,
    lineHeight: 18,
  },
  albumView: {
    flex: 0.4,
    maxWidth: 200,
    alignItems: 'center',
  },
  chatView: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  albumTitle: {
    width: '90%',
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
  albumTitleText: {
    fontSize: 14,
    color: '#F3F3F3',
  },
  name: {
    fontSize: 20,
    top: 5,
    textAlign: 'right',
    width: '100%',
  },
  headerUsername: {
    height: 35,
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    paddingBottom: 4,
    paddingLeft: 118,
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    width: '100%',
    overflow: 'hidden',
  },
  reportButton: {
    width: 23,
    height: 23,
    borderRadius: 100,
    backgroundColor: '#FF005A',
    position: 'absolute',
    left: -7,
    zIndex: 100,
    bottom: -5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 3,
  },
  reportButtonTouch: {
    width: 38,
    height: 38,
    justifyContent: 'center',
    alignItems: 'center',
  },
  reportIcon: {},
  chatImageView: {
    zIndex: 50,
    width: 110,
    height: 110,
    overflow: 'hidden',
    borderRadius: 20,
    elevation: 10,
    borderColor: '#F3F3F3',
    borderWidth: 2.5,
  },
});

export default ({ navigation, route }: any) => {
  const { user } = route.params;
  const { account } = useContext(AccountContext);
  const [image, setImage] = useState('');
  const [reportModel, setReportModel] = useState(false);
  const [imageOriginal, setImageOriginal] = useState('');
  const [imagePreviewUrl, setPreviewUrl] = useState('');
  const [visible, setVisible] = useState(false);
  const [isLock, setLock] = useState(true);
  useEffect(() => {
    if (!isEmpty(user.profile)) {
      Asset.loadAsync(user.profile[0])
        .then(async (assets) => {
          if (!isEmpty(assets)) {
            // const resizeImage = await ImageManipulator.manipulateAsync(
            //   assets[0].localUri as any,
            //   [],
            //   { compress: 1, format: ImageManipulator.SaveFormat.PNG },
            // );
            setImage(assets[0].localUri as any);
            setImageOriginal(assets[0].localUri as any);
          }
        })
        .catch(() => {
          setImage(user.profile[0] as any);
          setImageOriginal(user.profile[0] as any);
        });
    }
    return () => {
      setImage('');
      setImageOriginal('');
    };
  }, [user]);
  useEffect(() => {
    (async () => {
      const db = firebase.firestore();
      const res = await db.collection('Config').doc('general').get();
      const data = res.data();
      // .then((res: any) => {
      //   setOptions(res.data());
      // });
      const currentDate = new Date(Date.now());
      if (!data.subscribe) {
        setLock(false);
      } else if (account?.sex === 'אישה') {
        setLock(false);
      } else if (
        account?.subscription &&
        account?.expiredIn &&
        currentDate < account?.expiredIn
      ) {
        setLock(false);
      }
    })();
  }, [account]);
  const distance =
    getDistance(
      account?.coords || {
        latitude: 32.0852997,
        longitude: 34.7818064,
      },
      user.coords || {
        latitude: 32.0852997,
        longitude: 34.7818064,
      },
    ) / 1000;
  return (
    <Background color="#F3F3F3" type="dark">
      <ErrorModal
        buttonText="דווח"
        title="דווח על משתמש"
        text="בטיחותם של המשתמשים שלנו חשובה לנו מאוד"
        visible={reportModel}
        setVisible={() => {
          setReportModel(false);
        }}
        image={<Image source={BlockIcon} />}
        callback={async () => {
          const sendReportEmail = firebase
            .functions()
            .httpsCallable('sendReportEmail');
          sendReportEmail({ reportsId: user.uid, userId: account?.uid });
          setReportModel(false);
        }}
      />
      <ImagePreview
        image={imagePreviewUrl}
        visible={visible}
        setVisible={() => setVisible(false)}
      />
      <View style={styles.root}>
        <View style={styles.header}>
          <View style={styles.headerTop}>
            <View style={styles.headerUsername}>
              <Text
                numberOfLines={1}
                style={[commonStyle.textBold, styles.text, styles.name]}>
                {user.name as string}
              </Text>
            </View>
            <View style={styles.infoTextView}>
              <Text style={[commonStyle.textBold, styles.text]}>
                {new Date(Date.now()).getFullYear() -
                  (new Date(user.birthday) as Date).getFullYear()}
              </Text>
              <View style={[styles.divider]} />
              {/* <Text style={[commonStyle.textBold, styles.text]}>
                {user.status as string}
              </Text>
              <View style={[styles.divider]} /> */}
              <Text
                numberOfLines={1}
                style={[commonStyle.textBold, styles.text]}>
                {`${distance.toFixed(distance < 20 ? 1 : 0)} ק"מ`}
              </Text>
            </View>
            <View style={styles.imageView}>
              {isEmpty(image) ? (
                <Skeleton.Box rounded={20} w="100%" h="100%" bg="gray800" />
              ) : (
                <>
                  <TouchableOpacity
                    onPress={() => {
                      setPreviewUrl(imageOriginal);
                      setVisible(true);
                    }}
                    style={styles.chatImageView}>
                    <Image
                      resizeMode="stretch"
                      style={styles.image}
                      source={{
                        uri: image,
                      }}
                    />
                  </TouchableOpacity>

                  <View style={styles.reportButton}>
                    <TouchableOpacity
                      onPress={() => {
                        setReportModel(true);
                      }}
                      style={styles.reportButtonTouch}>
                      <MaterialIcons
                        name="block"
                        size={16}
                        color="#F3F3F3"
                        style={styles.reportIcon}
                      />
                    </TouchableOpacity>
                  </View>
                </>
              )}
            </View>
            <View style={styles.homeButtonView}>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('Home');
                }}
                style={styles.homeButton}>
                <MaterialIcons name="home" size={30} color="#FF005A" />
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.headerBottom}>
            <View style={styles.infoSubTextView}>
              <Text style={[commonStyle.text, styles.subInfo]}>
                {`${user.length / 100} מ'`}
              </Text>
              <Text style={[commonStyle.text, styles.subInfo]}>
                {user.body}
              </Text>
              <Text style={[commonStyle.text, styles.subInfo]}>
                {user.relationship[0]}
              </Text>
              <Text style={[commonStyle.text, styles.subInfo]}>
                {user.interesting}
              </Text>
            </View>
            <View style={styles.aboutTextView}>
              <Text
                numberOfLines={2}
                style={[commonStyle.text, styles.aboutText]}>
                {`"${user.about}"`}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.body}>
          <View style={styles.albumView}>
            <View style={styles.albumTitle}>
              <Entypo name="camera" size={28} color="#E3F0EF" />
              <Text
                numberOfLines={1}
                style={[commonStyle.textBold, styles.albumTitleText]}>
                גלריית תמונות
              </Text>
            </View>
            <Album
              onImageSelect={(url) => {
                setPreviewUrl(url);
                setVisible(true);
              }}
              albumUrls={user.album}
            />
          </View>
          <View style={styles.chatView}>
            <ChatView
              lock={isLock}
              receive={user}
              sender={account as IUserAccount}
            />
          </View>
        </View>
      </View>
    </Background>
  );
};
