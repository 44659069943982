import React, { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Spinner } from '@ui-kitten/components';

import commonStyle from '../common/style';

const Styles = (props: IButtonTextStyleProps) =>
  StyleSheet.create({
    buttonView: {
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 50,
      backgroundColor: props.background,
      opacity: props.disable ? 0.2 : 1,
      paddingVertical: props.height,
      width: props.width,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: props.background ? 0.34 : 0,
      shadowRadius: 6.27,
      elevation: props.background ? 5 : 0,
    },
    text: {
      fontSize: props.fontSize || 25,
      color: props.color,
      marginBottom: 4,
      fontWeight: '700',
      textAlign: 'center',
      alignSelf: 'center',
    },
    spinnerView: {
      paddingHorizontal: 15,
    },
  });

export interface IButtonTextStyleProps {
  width: number;
  background: string;
  color: string;
  fontSize?: number;
  disable: boolean;
  height: number;
}
export interface IButtonTextProps {
  title: string;
  style: IButtonTextStyleProps;
  onClick: () => Promise<any>;
}

export interface IButtonTextInnerProps {
  title: string;
  style: IButtonTextStyleProps;
  styles: ReturnType<typeof Styles>;
}

const innerContent = ({ title, styles }: IButtonTextInnerProps) => (
  <>
    <Text style={[commonStyle.textBold, styles.text]}>{title}</Text>
  </>
);

const TextButton = ({
  title,
  style,
  onClick = () => Promise.resolve(),
}: IButtonTextProps) => {
  const styles = Styles(style);
  const [loading, setLoading] = useState(false);
  return loading ? (
    <View style={styles.spinnerView}>
      <Spinner size="medium" />
    </View>
  ) : (
    <TouchableOpacity
      disabled={style.disable}
      onPress={() => {
        setLoading(true);
        onClick().then(() => {
          setLoading(false);
        });
      }}>
      {style.background ? (
        <View style={styles.buttonView}>
          {innerContent({ title, style, styles })}
        </View>
      ) : (
        <View style={styles.buttonView}>
          {innerContent({ title, style, styles })}
        </View>
      )}
    </TouchableOpacity>
  );
};

export default TextButton;
