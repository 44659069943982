/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { isEmpty } from 'lodash';
import { v4 } from 'react-native-uuid';
import * as ImageManipulator from 'expo-image-manipulator';

import firebase from '../../../services/firebase';

export interface ValuesType {
  name: string;
  sex: string;
  birthday: Date;
  length: number;
  body: string[];
  interesting: string;
  // status: string;
  relationship: string[];
  profile: any;
  album: any[];
  about: string;
}

export default async (
  values: ValuesType,
  context: any,
  setContext: (key: any, val: any) => void,
  next: (end: boolean) => void,
) => {
  console.log(JSON.stringify(values));

  if (
    isEmpty(values.name) ||
    isEmpty(values.sex) ||
    isEmpty(values.body) ||
    isEmpty(values.interesting) ||
    // isEmpty(values.status) ||
    isEmpty(values.relationship) ||
    isEmpty(values.about) ||
    !values.birthday ||
    !values.length
  ) {
    return { status: 'error', title: 'שגיאה', msg: 'חלק מהפרטים חסרים' };
  }
  if (!isDateValid(values.birthday)) {
    return {
      status: 'error',
      title: 'שגיאה',
      msg: 'הגיל המינימלי לשימוש באפלקציה הוא 18',
    };
  }
  // if (!/^[a-z\u05D0-\u05EA']+$/i.test(values.name)) {
  //   return { status: 'error', title: 'שגיאה', msg: 'יש לך הכניס רק שם בעברית' };
  // }

  return uploadProfile(values, context.user.user)
    .then(() => {
      next(false);
      return { status: 'ok' };
    })
    .catch((e) => {
      firebase.auth().signOut();
      return { status: 'error', title: 'שגיאה', msg: 'שגיאה לא צפויה' };
    });
};

export const isDateValid = (date: Date) => {
  if (!date) {
    return false;
  }
  return new Date(Date.now()).getFullYear() - date.getFullYear() >= 18;
};

const uploadProfile = async (profile: ValuesType, user: any) => {
  const db = firebase.firestore();
  return db
    .collection('Users')
    .doc(user.uid)
    .set(
      {
        uid: user.uid,
        name: profile.name,
        sex: profile.sex,
        body: profile.body,
        about: profile.about,
        interesting: profile.interesting,
        length: profile.length,
        // status: profile.status,
        relationship: profile.relationship,
        lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
        birthday: firebase.firestore.Timestamp.fromDate(profile.birthday),
      },
      { merge: true },
    );
};
