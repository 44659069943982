import { Asset } from 'expo-asset';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  FlatList,
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { Skeleton } from 'react-native-magnus';
import * as ImageManipulator from 'expo-image-manipulator';

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
    maxHeight: '65vh',
    paddingTop: 0,
  },
  image: {
    width: 105,
    alignSelf: 'center',
    height: 100,
    marginVertical: 6,
    borderRadius: 10,
  },
});

export interface IAlbumProps {
  albumUrls: string[];
  onImageSelect: (url: string) => void;
}

const ImageInAlbum = ({ uri, onSelect }: any) => {
  const [image, setImage] = useState('');
  const [original, setOriginal] = useState('');

  useEffect(() => {
    if (!isEmpty(uri)) {
      Asset.loadAsync(uri)
        .then(async (assets) => {
          if (!isEmpty(assets)) {
            setImage(assets[0].localUri as any);
            setOriginal(assets[0].localUri as any);
          }
        })
        .catch(() => {
          setImage(uri);
        });
    }
    return () => {
      setImage('');
      setOriginal('');
    };
  }, [uri]);
  return (
    <TouchableOpacity
      onPress={() => {
        if (!isEmpty(original)) {
          return onSelect(original);
        }
        return '';
      }}
      style={{ alignItems: 'center', justifyContent: 'center' }}>
      {isEmpty(image) ? (
        <Skeleton.Box w={105} h={100} mb={5} mt={5} bg={'gray800'} />
      ) : (
        <Image
          style={styles.image}
          resizeMode="stretch"
          source={{
            uri: image,
          }}
        />
      )}
    </TouchableOpacity>
  );
};

const Album = ({ albumUrls, onImageSelect }: IAlbumProps) => {
  return (
    <FlatList
      getItemLayout={(data, index) => ({
        length: 95,
        offset: 95 * index,
        index,
      })}
      style={styles.root}
      keyExtractor={(item, index) => String(index)}
      data={albumUrls}
      renderItem={({ item }) => (
        <ImageInAlbum onSelect={onImageSelect} uri={item} />
      )}
    />
  );
};

export default Album;
