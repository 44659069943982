/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';

import { IInputProps } from '../common/interfaces';
import InputText from './inputText';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '70%',
    flex: 0.7,
  },
});
export interface IExpiryInputProps extends IInputProps {}

const ExpiryInput = ({ value, onValChange }: IExpiryInputProps) => {
  const [month, setMonth] = useState((value as any)?.month);
  const [year, setYear] = useState((value as any)?.year);
  useEffect(() => {
    onValChange && onValChange({ month, year } as any);
  }, [month, year]);
  return (
    <View style={styles.container}>
      <InputText
        value={month}
        placeholder="MM"
        type="number"
        onValChange={setMonth}
        style={{
          rootWidth: 80,
          borderRadius: 8,
          color: '#1A1A1A',
          borderColor: '#00A4FF',
          backgroundColor: '#FAF7F2',
        }}
      />
      <InputText
        value={year}
        type="number"
        placeholder="YY"
        onValChange={setYear}
        style={{
          rootWidth: 80,
          borderRadius: 8,
          color: '#1A1A1A',
          borderColor: '#00A4FF',
          backgroundColor: '#FAF7F2',
        }}
      />
    </View>
  );
};

export default ExpiryInput;
