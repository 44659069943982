/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable global-require */
import 'react-native-gesture-handler';
import React, { useEffect } from 'react';
import * as Updates from 'expo-updates';
import { NavigationContainer } from '@react-navigation/native';
import { useFonts } from 'expo-font';
import { Helmet } from 'react-helmet';
import {
  SafeAreaProvider,
  initialWindowMetrics,
} from 'react-native-safe-area-context';
// import { Root } from 'popup-ui';
import * as eva from '@eva-design/eva';
import { ApplicationProvider } from '@ui-kitten/components';
import { ThemeProvider } from 'react-native-magnus';
import { I18nManager, View } from 'react-native';
import { AuthProvider } from './src/context/authentication';
import RootNavigator from './src/navigations/rootNavigator';
import { myTheme } from './src/common/custom-theme';
import { AccountProvider } from './src/context/account';
import Splash from './src/screens/splash/splash';
import { FilterProvider } from './src/context/filters';

import './fix_bug';
export default function App() {
  useEffect(() => {
    (async () => {
      if (I18nManager.isRTL) {
        await I18nManager.forceRTL(false);
        await I18nManager.allowRTL(false);
        await Updates.reloadAsync();
      }
    })();
  }, []);
  useEffect(() => {
    document.title = `Be My Baby : הכרויות אמידים באיכותיות`;
  });
  const [isInitialized, setInitialized] = React.useState(false);
  const [loaded] = useFonts({
    'Heebo-Regular': require('./assets/fonts/Heebo-Regular.ttf'),
    'Heebo-Bold': require('./assets/fonts/Heebo-Bold.ttf'),
    uniflex: require('./assets/fonts/uniflex.ttf'),
  });
  useEffect(() => {
    setInitialized(true);
  }, [loaded]);
  return !isInitialized ? (
    <ApplicationProvider {...eva} theme={{ ...eva.dark, ...myTheme }}>
      <Splash />
    </ApplicationProvider>
  ) : (
    <SafeAreaProvider
      initialMetrics={initialWindowMetrics}
      style={{ backgroundColor: '#1a1a1a' }}>
      <AuthProvider>
        <FilterProvider>
          <AccountProvider>
            <ThemeProvider>
              <ApplicationProvider {...eva} theme={{ ...eva.dark, ...myTheme }}>
                <NavigationContainer>
                  {/* <Root> */}
                  {/* <View style={{ width: 380, height: 600 }}> */}
                  <Helmet>
                    <title>Be My Baby : הכרויות אמידים באיכותיות</title>
                    <meta
                      name="description"
                      content="כשיהלום פוגש כתר.
יהלום בודד אינו אותו היהלום כאשר הוא משובץ בטבעת או בשרשרת יוקרתית.
בעולם בו אישה היא יהלום, אתר Be My Baby מאפשר לכל יהלום לפגוש את התכשיט היוקרתי המתאים לו, או במילים אחרות, מאפשר לכל אישה או בחורה איכותית להתמזג עם גבר מרשים, אמיד ואדיב שישבץ אותה בעולמו היוקרתי והמפנק.
ב- Be My Baby תמצאו סביבה אינטימית, בטוחה, איכותית ורלוונטית מאוד עבור עבור משתמשיו שנבחרים בתהליך מיון קפדני."
                    />
                  </Helmet>
                  <View
                    style={{
                      width: '100%',
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'hidden',
                    }}>
                    <View
                      style={{
                        width: '100%',
                        maxWidth: 550,
                        minWidth: '35vw',
                        flex: 1,
                        backgroundColor: 'red',
                        overflow: 'hidden',
                      }}>
                      <RootNavigator />
                    </View>
                  </View>

                  {/* </View> */}
                  {/* </Root> */}
                </NavigationContainer>
              </ApplicationProvider>
            </ThemeProvider>
          </AccountProvider>
        </FilterProvider>
      </AuthProvider>
    </SafeAreaProvider>
  );
}
