/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import {
  AntDesign,
  Ionicons,
  MaterialCommunityIcons,
} from '@expo/vector-icons';
import { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import About from '../screens/about/about';
import Registration from '../screens/registration/registration';
import Home from '../screens/home/home';
import Chat from '../screens/chat/chat';

import SideBar from '../components/sidebar';
import { AuthContext } from '../context/authentication';
import Splash from '../screens/splash/splash';
import { AccountContext } from '../context/account';
import Profile from '../screens/profile/profile';

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const RootNavigator = () => {
  const { userInfo, autoLogin, isLoading } = useContext(AuthContext);
  const [showLogin, setLogin] = useState(false);
  const account = useContext(AccountContext);

  useEffect(() => {
    if (!(userInfo.uid && autoLogin)) {
      setTimeout(() => {
        setLogin(true);
      }, 4000);
    } else {
      setLogin(false);
    }
  }, [userInfo.uid, autoLogin]);
  if (isLoading) {
    return <Splash />;
  }
  if (userInfo.uid && autoLogin && account.isLoading) {
    return <Splash />;
  }
  // if (account.account?.uid) {
  //   return <Splash />;
  // }
  return userInfo.uid && autoLogin ? (
    <Stack.Navigator>
      <Stack.Screen
        name="Home"
        component={DrawerNavigator}
        options={{
          headerShown: false,
          title: `Be My Baby : הכרויות אמידים באיכותיות`,
        }}
      />
      <Stack.Screen
        name="Chat"
        component={Chat}
        options={{
          headerShown: false,
          title: `Be My Baby : הכרויות אמידים באיכותיות`,
        }}
      />
    </Stack.Navigator>
  ) : showLogin ? (
    <Stack.Navigator>
      <Stack.Screen
        name="Login"
        component={Registration}
        options={{
          headerShown: false,
          title: `Be My Baby : הכרויות אמידים באיכותיות`,
        }}
      />
      <Stack.Screen
        name="Registration"
        component={Registration}
        options={{
          headerShown: false,
          title: `Be My Baby : הכרויות אמידים באיכותיות`,
        }}
      />
    </Stack.Navigator>
  ) : (
    <Splash />
  );
};

export const DrawerNavigator = () => {
  const { logout } = useContext(AuthContext);
  const { account } = useContext(AccountContext);

  return (
    <Drawer.Navigator
      drawerContent={(props) => (
        <SideBar
          profileImg={
            account && account.profile && account.profile[0]
              ? account.profile[0]
              : null
          }
          subscribe={account && account.expiredIn ? account.expiredIn : null}
          name={account && account.name ? account.name : ''}
          {...props}
        />
      )}
      drawerPosition="right"
      initialRouteName="Menu">
      <Drawer.Screen
        name="Menu"
        options={{
          drawerLabel: 'none',
          drawerIcon: (<AntDesign name="profile" />) as any,
          title: `Be My Baby : הכרויות אמידים באיכותיות`,
        }}
        component={Home}
      />
      <Drawer.Screen
        name="Profile"
        options={{
          drawerLabel: 'עריכת פרופיל',
          drawerIcon: (<AntDesign name="profile" />) as any,
          title: `Be My Baby : הכרויות אמידים באיכותיות`,
        }}
        component={Profile}
      />
      {/* <Drawer.Screen
        name="Men2u"
        options={{
          drawerLabel: 'שינוי אמצעי תשלום',
          drawerIcon: (<Entypo name="credit-card" />) as any,
        }}
        component={View}
      /> */}
      <Drawer.Screen
        name="About"
        options={{
          drawerLabel: 'אודות',
          drawerIcon: (
            <Ionicons name="ios-information-circle-outline" />
          ) as any,
          title: `Be My Baby : הכרויות אמידים באיכותיות`,
        }}
        component={About}
      />
      <Drawer.Screen
        name="logout"
        options={{
          drawerLabel: 'התנתק',
          drawerIcon: (<MaterialCommunityIcons name="logout-variant" />) as any,
          title: `Be My Baby : הכרויות אמידים באיכותיות`,
        }}
        listeners={
          {
            tabPress: (e: any) => {
              e.preventDefault();
              logout();
            },
          } as any
        }
        component={View}
      />
    </Drawer.Navigator>
  );
};

export default RootNavigator;
