/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Dimensions,
  ImageBackground,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { useKeyboard } from '../../../../common/hooks';
import commonStyle from '../../../../common/style';
import TextButton from '../../../../components/textButton';
import background from '../../../../../assets/background.png';
import { mantra } from '../../../../common/mantra';
import background1 from '../../../../../assets/screen/one.jpg';
import background2 from '../../../../../assets/screen/two.jpg';
import background3 from '../../../../../assets/screen/three.jpg';
import background4 from '../../../../../assets/screen/four.jpg';
import background5 from '../../../../../assets/screen/five.jpg';
import background6 from '../../../../../assets/screen/six.jpg';
import background7 from '../../../../../assets/screen/seven.jpg';

const backgrounds = [
  background1,
  background2,
  background3,
  background4,
  background5,
  background6,
  background7,
  background1,
];
const style = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    opacity: 1,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: Dimensions.get('window').height,
    paddingBottom: 20,
  },
  inputView: {
    flex: 0.55,
    alignItems: 'center',
    width: '100%',
    paddingTop: 60,
  },
  imageView: {
    height: 0,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mantraView: {
    flex: 1,
    width: '90%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    color: '#222222',
  },
  subTitle: {
    fontSize: 12,
    textAlign: 'center',
    paddingTop: 2,
    color: '#222222',
  },
  titleView: {
    width: '100%',
    paddingBottom: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputComponentView: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitView: {
    flex: 0.3,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  authorText: {
    bottom: 15,
    left: 0,
    position: 'absolute',
    color: '#000000',
    fontSize: 17,
  },
  mantra: {
    paddingBottom: 45,
  },
});
export interface IStepProps {
  title?: string;
  subTitle?: string;
  input: any;
  index?: number;
  onNext?: () => void;
  validate?: boolean;
}
const Step = ({
  title,
  subTitle,
  input,
  onNext,
  index,
  validate,
}: IStepProps) => {
  const isKeyboardVisible = useKeyboard();

  return (
    <ImageBackground style={style.root} source={backgrounds[index || 0]}>
      <View style={[style.inputView, isKeyboardVisible ? {} : {}]}>
        {title ? (
          <View style={style.titleView}>
            <Text style={[commonStyle.textBold, style.title]}>{title}</Text>
            {subTitle ? (
              <Text style={[commonStyle.text, style.subTitle]}>{subTitle}</Text>
            ) : (
              <></>
            )}
          </View>
        ) : (
          <></>
        )}
        <View style={style.inputComponentView}>
          {React.cloneElement(input)}
        </View>
        <View style={style.submitView}>
          <TextButton
            title="אישור"
            onClick={async () => {
              onNext();
            }}
            style={{
              width: 110,
              fontSize: 16,
              height: 5,
              background: '#05A4FF',
              color: '#1a1a1a',
              disable: validate,
            }}
          />
        </View>
      </View>
      <View style={style.imageView}>
        {/* <Text style={[commonStyle.text, { fontSize: 80 }]}>X</Text> */}
      </View>
      {false ? (
        <View style={style.mantraView}>
          <Text style={[commonStyle.text, style.mantra]}>
            {mantra[index] ? mantra[index] : mantra[0]}
          </Text>
          <Text style={[commonStyle.text, style.authorText]}>
            מאת יואב ברנזון - יועץ זוגי
          </Text>
        </View>
      ) : (
        <></>
      )}
    </ImageBackground>
  );
};

export default Step;
