/* eslint-disable react-native/no-inline-styles */
import { StatusBar, StatusBarStyle } from 'expo-status-bar';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Constants from 'expo-constants';

const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: '#1a1a1a',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
  },
});

export interface IBackgroundProps {
  children: any;
  color?: string;
  type?: StatusBarStyle;
}

const Background = ({ children, color, type }: IBackgroundProps) => {
  const insets = useSafeAreaInsets();
  return (
    <>
      <StatusBar style={type || 'auto'} translucent />
      <View
        style={{
          width: '100%',
          height: insets.top,
          backgroundColor: color || '#1a1a1a',
        }}
      />
      <View
        style={[
          styles.background,
          {
            paddingBottom: insets.bottom,
          },
        ]}>
        {children}
      </View>
    </>
  );
};

export default Background;
