/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import { AntDesign } from '@expo/vector-icons';
import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import { flatten, intersectionBy, isEmpty, uniqBy } from 'lodash';
import { IUserAccount } from '../common/interfaces';
import firebase from '../services/firebase';
import commonStyle from '../common/style';
import InboxItem from './inboxItem';
import { Message } from './chatView';
import ErrorModal from './errorModal';

export interface IInboxProps {
  account: IUserAccount;
  navigation: any;
}

export interface IInboxState {
  isLoading: boolean;
  index: number;
  targetToDelete: string[];
  confirmModal: boolean;

  messagesGroups: Message[][];
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: '#EA0554',
    flex: 0.95,
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    borderBottomRightRadius: 15,
    borderTopRightRadius: 15,
    overflow: 'hidden',
  },
  body: {
    flex: 1,
    backgroundColor: '#F1E9DE',
  },
  header: {
    height: 40,
    justifyContent: 'center',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  headerText: {
    fontSize: 18,
    paddingRight: 5,
  },
  footer: {
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerInner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
});

class Inbox extends Component<IInboxProps, IInboxState> {
  subscribeSend: any;

  subscribeReceive: any;

  flatListRef: any;

  constructor(props: IInboxProps) {
    super(props);
    this.state = {
      messagesGroups: [[]],
      index: 0,
      isLoading: true,
      confirmModal: false,
      targetToDelete: [],
    };
    this.subscribeReceive = null;
    this.subscribeSend = null;
    this.flatListRef = React.createRef();
  }

  async componentDidMount() {
    const { account } = this.props;
    this.subscribeSend = firebase
      .firestore()
      .collection('Messages')
      .where('senderId', '==', account.uid)
      .onSnapshot((querySnapshot: any) => this.onSnapshot(querySnapshot));
    this.subscribeReceive = firebase
      .firestore()
      .collection('Messages')
      .where('receiveId', '==', account.uid)
      .onSnapshot((querySnapshot: any) => this.onSnapshot(querySnapshot));
  }

  componentDidUpdate(nextProps: IInboxProps) {
    const { account } = this.props;

    if (nextProps!.account !== account) {
      this.subscribeSend = firebase
        .firestore()
        .collection('Messages')
        .where('senderId', '==', nextProps.account.uid)
        .onSnapshot((querySnapshot: any) => this.onSnapshot(querySnapshot));
      this.subscribeReceive = firebase
        .firestore()
        .collection('Messages')
        .where('receiveId', '==', nextProps.account.uid)
        .onSnapshot((querySnapshot: any) => this.onSnapshot(querySnapshot));
    }
  }

  componentWillUnmount() {
    return [this.subscribeSend(), this.subscribeReceive()];
  }

  onSnapshot(querySnapshot: any) {
    const messages: Message[] = [];
    const removeMessages: Message[] = [];
    querySnapshot.docChanges().forEach((change: any) => {
      if (change.type === 'removed') {
        const _data = change.doc.data();
        removeMessages.push({
          ..._data,
          timestamp: _data?.timestamp?.toDate
            ? _data.timestamp.toDate()
            : new Date(Date.now()),
          key: change.doc.id,
        });
      }
    });
    querySnapshot.forEach((documentSnapshot: any) => {
      const data = documentSnapshot.data();

      messages.push({
        ...data,
        timestamp: data?.timestamp?.toDate
          ? data.timestamp.toDate()
          : new Date(Date.now()),
        key: documentSnapshot.id,
      });
    });
    let messagesGroups = this.onReceiveNewMessages(messages);
    messagesGroups = this.filterDeleteMessages(
      messagesGroups,
      removeMessages.map((msg) => msg.key),
    );
    this.setState({
      isLoading: false,
      messagesGroups,
    });
  }

  onReceiveNewMessages(messages: Message[]) {
    const messagesGroups = intersectionBy(
      [...messages, ...flatten(this.state.messagesGroups)],
      'key',
    ).reduce((messageGroups, message) => {
      messageGroups[message.receiveId as string] = [
        ...(messageGroups[message.receiveId as string] || []),
        message,
      ];
      messageGroups[message.senderId as string] = [
        ...(messageGroups[message.senderId as string] || []),
        message,
      ];
      return messageGroups;
    }, {} as any);
    return Object.keys(messagesGroups).reduce((messagesGroup, groupsKey) => {
      if (groupsKey === this.props.account.uid) return messagesGroup;
      messagesGroup.push(
        uniqBy(
          messagesGroups[groupsKey].sort((x: any, y: any) => {
            return y.timestamp - x.timestamp;
          }),
          'key',
        ),
      );
      return messagesGroup;
    }, [] as any);
  }

  async deleteMessages([senderId, receiveId]: any) {
    const deleteMessageKey: string[] = [];
    const messagesA = await firebase
      .firestore()
      .collection('Messages')
      .where('senderId', '==', senderId)
      .where('receiveId', '==', receiveId)
      .get();
    const messagesB = await firebase
      .firestore()
      .collection('Messages')
      .where('senderId', '==', receiveId)
      .where('receiveId', '==', senderId)
      .get();
    messagesA.forEach((documentSnapshot: any) => {
      deleteMessageKey.push(documentSnapshot.id);
      documentSnapshot.ref.delete();
    });
    messagesB.forEach((documentSnapshot: any) => {
      deleteMessageKey.push(documentSnapshot.id);
      documentSnapshot.ref.delete();
    });

    const messagesGroups = this.state.messagesGroups.reduce(
      (messageGroups, messages) => {
        const filterMessages = messages.filter((message) => {
          return !deleteMessageKey.includes(message.key);
        });
        if (isEmpty(filterMessages)) return messageGroups;
        messageGroups.push(filterMessages);
        return messageGroups;
      },
      [] as Message[][],
    );
    this.setState({ messagesGroups });
  }

  filterDeleteMessages(messageGroup: Message[][], deleteMessageKey: string[]) {
    return messageGroup.reduce((messageGroups, messages) => {
      const filterMessages = messages.filter((message) => {
        return !deleteMessageKey.includes(message.key);
      });
      if (isEmpty(filterMessages)) return messageGroups;
      messageGroups.push(filterMessages);
      return messageGroups;
    }, [] as Message[][]);
  }

  render() {
    return (
      <>
        <ErrorModal
          buttonText="מחק"
          title="מחק הודעות"
          text="שים לב הודעות ימחקו גם אצל הנמען לצמיתות"
          visible={this.state.confirmModal}
          setVisible={() => {
            this.setState({
              targetToDelete: [],
            });
            this.setState({ confirmModal: false });
          }}
          callback={() => {
            this.deleteMessages(this.state.targetToDelete as any).then(() => {
              this.setState({
                targetToDelete: [],
              });
              this.setState({ confirmModal: false });
            });
          }}
        />
        <View style={styles.root}>
          <View style={styles.header}>
            <AntDesign name="message1" size={22} color="#F3F3F3" />
            <Text style={[commonStyle.textBold, styles.headerText]}>
              תיבת שיחות
            </Text>
          </View>
          <View style={styles.body}>
            {!isEmpty(this.state.messagesGroups) ? (
              <FlatList
                ref={(ref) => {
                  this.flatListRef = ref;
                }}
                getItemLayout={(data, index) => ({
                  length: 95,
                  offset: 95 * index,
                  index,
                })}
                onScroll={(e) => {
                  const offset = e.nativeEvent.contentOffset.y;
                  const index = parseInt(((offset / 95) as unknown) as string);
                  this.setState({ index });
                }}
                keyExtractor={(item, index) => String(index)}
                data={
                  this.state.isLoading
                    ? new Array(20).fill(['none'])
                    : this.state.messagesGroups.sort((x: any[], y: any[]) => {
                        return y[0].timestamp - x[0].timestamp;
                      })
                }
                renderItem={({ item }: { item: Message[] }) => {
                  const lastMsg = item[0];
                  return (
                    <InboxItem
                      message={lastMsg}
                      newMessages={
                        item.filter((message: Message) => {
                          return (
                            message.status !== 'read' &&
                            message.senderId !== this.props.account.uid
                          );
                        }).length
                      }
                      account={this.props.account}
                      onDelete={(message: Message) => {
                        this.setState({
                          targetToDelete: [
                            message.senderId || '',
                            message.receiveId || '',
                          ],
                        });
                        this.setState({ confirmModal: true });
                      }}
                      isLoading={this.state.isLoading}
                      navigation={this.props.navigation}
                      accountId={
                        this.props.account.uid === lastMsg.senderId
                          ? lastMsg.receiveId
                          : lastMsg.senderId
                      }
                    />
                  );
                }}
              />
            ) : (
              <></>
            )}
          </View>
          <View style={styles.footer}>
            <TouchableOpacity
              onPress={() => {
                if (
                  this.flatListRef &&
                  this.flatListRef!.scrollToEnd &&
                  this.state.index + 1 < this.state.messagesGroups.length
                ) {
                  (this.flatListRef as any).scrollToIndex({
                    index: this.state.index + 1,
                  });
                  this.setState({ index: this.state.index });
                }
              }}
              style={styles.footerInner}>
              <AntDesign name="caretdown" size={21} color="#FFFFFF" />
            </TouchableOpacity>
          </View>
        </View>
      </>
    );
  }
}

export default Inbox;
