import React from 'react';
import {
  Modal,
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import ModalWeb from 'modal-react-native-web';
import commonStyle from '../common/style';
import ErrorIcon from '../../assets/error.png';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modal: {
    flex: 1,
    zIndex: 10,
    width: '100%',
  },
  innerView: {
    width: '60%',
    height: 260,
    maxWidth: 400,
    borderRadius: 40,
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: '#F5F5F5',
  },
  footer: {
    flex: 0.5,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    flex: 0.75,
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  body: {
    flex: 0.9,
    width: '100%',
    paddingHorizontal: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitButton: {
    backgroundColor: '#F50957',
    height: 40,
    width: 140,
    borderRadius: 100,
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 20,
  },
  logo: {
    width: 70,
    height: 70,
  },
  headerText: {
    color: '#616161',
    textAlign: 'center',
    fontSize: 20,
  },
  text: {
    color: '#616161',
    textAlign: 'center',
    fontSize: 16,
  },
  close: {
    position: 'absolute',
    top: 10,
    left: 15,
  },
  buttonInner: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '130%',
    height: '120%',
  },
});

export interface IErrorModalProps {
  visible: boolean;
  setVisible: () => void;
  callback: () => void;
  title: string;
  text: string;
  buttonText: string;
  image?: any;
}

const ErrorModal = ({
  title,
  visible,
  setVisible,
  callback,
  text,
  buttonText,
  image,
}: IErrorModalProps) => {
  if (Platform.OS === 'web') {
    return (
      <ModalWeb
        style={styles.modal}
        animationType="fade"
        transparent
        statusBarTranslucent
        onRequestClose={setVisible}
        visible={visible}>
        <View style={styles.root}>
          <View style={styles.innerView}>
            <View style={styles.header}>
              {image ? (
                React.cloneElement(image, { style: styles.logo })
              ) : (
                <Image source={ErrorIcon} style={styles.logo} />
              )}
              <TouchableOpacity style={styles.close} onPress={setVisible}>
                <FontAwesome name="close" size={28} color="#EB0454" />
              </TouchableOpacity>
            </View>
            <View style={styles.body}>
              <Text style={[commonStyle.textBold, styles.headerText]}>
                {title}
              </Text>
              <Text style={[commonStyle.text, styles.text]}>{text}</Text>
            </View>
            <View style={styles.footer}>
              <View style={styles.submitButton}>
                <TouchableOpacity style={styles.buttonInner} onPress={callback}>
                  <Text style={[commonStyle.textBold, styles.buttonText]}>
                    {buttonText}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </ModalWeb>
    );
  }
  return (
    <Modal
      style={styles.modal}
      animationType="fade"
      transparent
      statusBarTranslucent
      onRequestClose={setVisible}
      visible={visible}>
      <View style={styles.root}>
        <View style={styles.innerView}>
          <View style={styles.header}>
            {image ? (
              React.cloneElement(image, { style: styles.logo })
            ) : (
              <Image source={ErrorIcon} style={styles.logo} />
            )}
            <TouchableOpacity style={styles.close} onPress={setVisible}>
              <FontAwesome name="close" size={28} color="#EB0454" />
            </TouchableOpacity>
          </View>
          <View style={styles.body}>
            <Text style={[commonStyle.textBold, styles.headerText]}>
              {title}
            </Text>
            <Text style={[commonStyle.text, styles.text]}>{text}</Text>
          </View>
          <View style={styles.footer}>
            <View style={styles.submitButton}>
              <TouchableOpacity style={styles.buttonInner} onPress={callback}>
                <Text style={[commonStyle.textBold, styles.buttonText]}>
                  {buttonText}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ErrorModal;
