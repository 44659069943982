/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import Background from '../../components/background';
import { AccountContext } from '../../context/account';
import Personal from '../registration/pages/personal/personal';
import PersonalService from '../registration/services/personal';

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ({ navigation }: any) => {
  const { account } = useContext(AccountContext);
  const [values, setValues] = useState<any>({
    ...account,
    profile: (account?.profile || []).map((profile) => ({
      image: profile,
      upload: true,
    })),
    album: (account?.album || []).map((album) => ({
      image: album,
      upload: true,
    })),
  });

  const onNext = useCallback(async () => {
    return PersonalService(
      values,
      { user: { user: { uid: account?.uid } } },
      () => {},
      () => {
        navigation.goBack();
      },
    ) as any;
  }, [values, account]);
  return (
    <Background type="light">
      <View style={styles.root}>
        <Personal
          setValues={(val) => {
            setValues({ ...val });
          }}
          registration={false}
          context={[{ user: { user: { uid: account?.uid } } }]}
          onNext={onNext}
          values={values}
        />
      </View>
    </Background>
  );
};
