/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-native/no-inline-styles */
import { FontAwesome } from '@expo/vector-icons';
import { Spinner } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import ImageZoom from 'react-native-image-pan-zoom';

import {
  ImageBackground,
  Modal,
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
  Text,
  Dimensions,
  Platform,
} from 'react-native';

import ModalWeb from 'modal-react-native-web';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  modal: {
    flex: 1,
    width: '100%',
  },
  modalZoom: {
    flex: 1,
    width: '100%',
  },
  webImage: {
    width: '100%',
    flex: 1,
  },
  innerView: {
    width: '95%',
    borderRadius: 25,
    flex: 0.95,
    maxWidth: 380,
    maxHeight: 380,
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    resizeMode: 'contain',
    height: undefined,
    aspectRatio: 1,
  },
  loading: {
    width: '100%',
    flex: 1,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },
  close: {
    position: 'absolute',
    top: 12,
    left: 15,
  },
  spinnerView: {
    width: 180,
    height: 180,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
});

export interface IImagePreviewProps {
  onPress: () => void;
  imageUri: { uri: string };
}

const ImagePreview = ({
  visible,
  setVisible,
  image,
}: {
  visible: boolean;
  image: string;
  setVisible: () => void;
}) => {
  const [imageUrl, setImageUrl] = useState(image);
  useEffect(() => {
    setImageUrl(image);
    return () => {
      setImageUrl('');
    };
  }, [image]);
  const [zoom, setZoom] = useState(false);
  if (Platform.OS === 'web') {
    return (
      <ModalWeb
        style={styles.modal}
        animationType="fade"
        transparent
        statusBarTranslucent
        onRequestClose={setVisible}
        visible={visible}>
        <View style={styles.root}>
          <View style={styles.innerView}>
            <ImageBackground
              resizeMode="stretch"
              source={{ uri: imageUrl }}
              style={[styles.image, styles.webImage]}>
              <TouchableOpacity
                style={{ flex: 0.15, width: '30%' }}
                onPress={setVisible}>
                <FontAwesome
                  style={styles.close}
                  name="close"
                  size={36}
                  color="#EB0454"
                />
              </TouchableOpacity>
            </ImageBackground>
          </View>
        </View>
      </ModalWeb>
    );
  }
  return (
    <>
      <Modal
        visible={zoom}
        animationType="fade"
        transparent
        style={styles.modalZoom}
        statusBarTranslucent>
        {/* <ImageZoom
          cropWidth={Dimensions.get('window').width}
          cropHeight={Dimensions.get('window').height}
          imageWidth={390}
          responderRelease={() => {
            setZoom(false);
          }}
          imageHeight={390}>
          {
            (
              <TouchableWithoutFeedback>
                <Image
                  resizeMode="contain"
                  style={{ width: 390, height: 390, borderRadius: 10 }}
                  source={{
                    uri: imageUrl,
                  }}
                />
              </TouchableWithoutFeedback>
            ) as any
          }
        </ImageZoom> */}
      </Modal>
      <Modal
        style={styles.modal}
        animationType="fade"
        transparent
        statusBarTranslucent
        onRequestClose={setVisible}
        visible={visible}>
        <View style={styles.root}>
          <View style={styles.innerView}>
            {imageUrl ? (
              true ? (
                <ImageBackground
                  resizeMode="stretch"
                  source={{ uri: imageUrl }}
                  style={styles.image}>
                  <TouchableOpacity
                    onPressIn={() => {
                      setZoom(false);
                    }}
                    style={{ flex: 1, width: '100%' }}>
                    <TouchableOpacity
                      style={{ flex: 0.15, width: '30%' }}
                      onPress={setVisible}>
                      <FontAwesome
                        style={styles.close}
                        name="close"
                        size={36}
                        color="#EB0454"
                      />
                    </TouchableOpacity>
                  </TouchableOpacity>
                </ImageBackground>
              ) : (
                <></>
              )
            ) : (
              <View style={styles.loading}>
                <View style={styles.spinnerView}>
                  <Spinner />
                </View>
                <TouchableOpacity
                  style={{ flex: 1, width: '100%' }}
                  onPress={setVisible}>
                  <FontAwesome
                    style={styles.close}
                    name="close"
                    size={36}
                    color="#EB0454"
                  />
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </Modal>
    </>
  );
};

export default ImagePreview;
