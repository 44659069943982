/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';

const styles = StyleSheet.create({
  bar: {
    flex: 1,
    width: '100%',
    backgroundColor: 'blue',
  },
  root: {
    height: 60,
    flexDirection: 'row-reverse',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
  },
  circle: {
    width: 22,
    height: 22,
    marginHorizontal: 10,
    borderColor: '#ffffff',
    borderWidth: 1,
    borderRadius: 100,
  },
  activeCircle: {
    backgroundColor: '#05a4ff',
  },
});

export interface IProgressBarProps {
  steps: number;
  step: number;
  secretCallback: () => void;
}

const Circle = ({
  active,
  index,
  secretCallback,
}: {
  active: boolean;
  index: number;
  secretCallback: () => void;
}) => {
  const [clickCount, setClickCount] = useState(1);
  useEffect(() => {
    if (clickCount % 5 === 0) {
      secretCallback();
    }
  }, [clickCount, secretCallback]);
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (index === 0) {
          setClickCount(clickCount + 1);
        }
      }}>
      <View style={[styles.circle, active ? styles.activeCircle : {}]} />
    </TouchableWithoutFeedback>
  );
};

const Progressbar = ({
  steps = 0,
  step = 1,
  secretCallback,
}: IProgressBarProps) => {
  return (
    <View style={styles.root}>
      {new Array(steps).fill(0).map((s, index) => {
        return (
          <Circle
            secretCallback={secretCallback}
            index={index}
            key={index}
            active={index === step - 1}
          />
        );
      })}
    </View>
  );
};

export default Progressbar;
