/* eslint-disable react/no-array-index-key */
/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useState } from 'react';
import {
  StyleSheet,
  View,
  FlatList,
  Modal,
  Image,
  Text,
  TouchableOpacity,
  Keyboard,
  Platform,
  ScrollView,
} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import cardValidator from 'card-validator';
import { Spinner } from '@ui-kitten/components';
import ModalWeb from 'modal-react-native-web';
import payImage from '../../assets/payImage.png';
import commonStyle from '../common/style';
import InputText from './inputText';
import ExpiryInput from './expiryInput';
import { useKeyboardHeight } from '../common/hooks';
import firebase from '../services/firebase';
import ErrorModal from './errorModal';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  modal: {
    flex: 1,
    height: '100%',
    width: '100%',
  },
  innerView: {
    width: '90%',
    height: 490,
    borderRadius: 10,
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    flex: 0.8,
  },
  body: {
    flex: 0.85,
    width: '90%',
  },
  buttonView: {
    position: 'absolute',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    bottom: -30,
  },
  button: {
    height: 50,
    width: 140,
    borderRadius: 50,
    elevation: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
    backgroundColor: '#F40257',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonDisabled: {
    backgroundColor: '#5d5d5d',
  },
  textButton: {
    fontSize: 26,
  },
  innerButton: {
    flex: 1,
    width: '120%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  close: {
    flex: 0.08,
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '92%',
  },
  header: {
    flex: 0.065,
    paddingBottom: 30,
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerText: {
    fontSize: 18,
    color: '#005167',
  },
  content: {
    flex: 1,
    alignSelf: 'center',
    width: '100%',
  },
  option: {
    width: '100%',
    flexDirection: 'row-reverse',
    margin: 1,
    paddingTop: 15,
    paddingBottom: 2,
  },
  labelText: {
    width: 70,
    fontSize: 16,
    textAlign: 'right',
    color: '#1A1A1A',
  },
  inputView: {
    flex: 1,
    width: '100%',
    alignItems: 'flex-end',
  },
});

const options = [
  {
    key: 'name',
    label: 'שם בעל הכרטיס',
    input: (
      <InputText
        style={{
          borderRadius: 8,
          color: '#1A1A1A',
          borderColor: '#00A4FF',
          backgroundColor: '#FAF7F2',
        }}
      />
    ),
  },
  {
    key: 'cardNumber',
    label: 'מספר כרטיס',
    input: (
      <InputText
        type="number"
        style={{
          borderRadius: 8,
          color: '#1A1A1A',
          borderColor: '#00A4FF',
          backgroundColor: '#FAF7F2',
        }}
      />
    ),
  },
  {
    key: 'expiry',
    label: 'תוקף',
    input: <ExpiryInput />,
  },
  {
    key: 'cvv',
    label: 'מספר CVV',
    input: (
      <InputText
        type="number"
        placeholder="XXX"
        style={{
          rootWidth: '40%',
          borderRadius: 8,
          color: '#1A1A1A',
          borderColor: '#00A4FF',
          backgroundColor: '#FAF7F2',
        }}
      />
    ),
  },
];

export default ({
  visible,
  setVisible,
  userId,
  type,
}: {
  visible: boolean;
  setVisible: () => void;
  userId: string;
  type: string;
}) => {
  const [form, setForm] = useState<any>({
    cvv: '',
    expiry: { month: null, year: null },
    cardNumber: '',
    name: '',
  });
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [keyboardHeight] = useKeyboardHeight();

  useEffect(() => {
    let valid = true;
    const [month, year] = [
      parseInt(form.expiry.month, 10),
      parseInt(form.expiry.year, 10),
    ];

    if (
      !form.expiry ||
      !month ||
      !year ||
      month > 12 ||
      month < 1 ||
      year < 20
    ) {
      valid = false;
    }

    if (form.name.length < 1) {
      valid = false;
    }

    if (form.cvv.length !== 3) {
      valid = false;
    }
    if (!cardValidator.number(form.cardNumber).isValid) {
      valid = false;
    }
    setDisabled(!valid);
  }, [form]);
  const onClose = () => {
    setVisible();
  };
  const onSave = () => {
    const payForSubscription = firebase.functions().httpsCallable('subscribe');
    setLoading(true);
    Keyboard.dismiss();

    payForSubscription({
      subscribeType: type,
      userId,
      cardNumber: form.cardNumber,
      cardMonth: form.expiry.month,
      cardYear: form.expiry.year,
      firstName: form.name,
      lastName: '',
      street: '',
      cvv: form.cvv,
    }).then((res: any) => {
      console.log(error);

      if (res?.data?.error) {
        setError(true);
      }
      setLoading(false);
    });
    // setVisible();
  };

  if (Platform.OS === 'web') {
    return (
      <>
        <ErrorModal
          buttonText="נסה שוב"
          title="שגיאה בתשלום"
          text="קרתה שגיאה בביצוע תשלום אנא נסה שנית"
          visible={error}
          setVisible={() => {
            setError(false);
          }}
          callback={() => {
            setError(false);
          }}
        />
        <ModalWeb
          style={styles.modal}
          animationType="fade"
          transparent
          statusBarTranslucent
          onRequestClose={onClose}
          visible={visible && !error}>
          <View style={styles.root}>
            <View style={styles.innerView}>
              <View style={styles.close}>
                <TouchableOpacity onPress={onClose}>
                  <FontAwesome name="close" size={26} color="#005167" />
                </TouchableOpacity>
              </View>
              <View style={styles.body}>
                <View style={styles.header}>
                  <Text style={[commonStyle.textBold, styles.headerText]}>
                    תשלום
                  </Text>
                </View>
                <ScrollView style={styles.content}>
                  {options.map((option, index) => {
                    return (
                      <View key={index} style={styles.option}>
                        <Text style={[commonStyle.textBold, styles.labelText]}>
                          {option.label}
                        </Text>
                        <View style={styles.inputView}>
                          {React.cloneElement(option.input, {
                            value: form[option.key],
                            onValChange: (val: any) => {
                              setForm({ ...form, [option.key]: val });
                            },
                          })}
                        </View>
                      </View>
                    );
                  })}
                </ScrollView>
                <View
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Image
                    style={{
                      width: '80%',
                      height: 70,
                    }}
                    resizeMode="center"
                    source={payImage}
                  />
                </View>
              </View>
              <View style={styles.buttonView}>
                <View
                  style={[
                    styles.button,
                    disabled || loading ? styles.buttonDisabled : {},
                  ]}>
                  <TouchableOpacity
                    disabled={disabled || loading}
                    onPress={onSave}
                    style={styles.innerButton}>
                    {loading ? (
                      <Spinner size="medium" />
                    ) : (
                      <Text style={[commonStyle.text, styles.textButton]}>
                        לתשלום
                      </Text>
                    )}
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </ModalWeb>
      </>
    );
  }

  return (
    <>
      <ErrorModal
        buttonText="נסה שוב"
        title="שגיאה בתשלום"
        text="קרתה שגיאה בביצוע תשלום אנא נסה שנית"
        visible={error}
        setVisible={() => {
          setError(false);
        }}
        callback={() => {
          setError(false);
        }}
      />
      <Modal
        style={styles.modal}
        animationType="fade"
        transparent
        statusBarTranslucent
        onRequestClose={onClose}
        visible={visible && !error}>
        <View style={styles.root}>
          <View style={{ ...styles.innerView, marginBottom: keyboardHeight }}>
            <View style={styles.close}>
              <TouchableOpacity onPress={onClose}>
                <FontAwesome name="close" size={26} color="#005167" />
              </TouchableOpacity>
            </View>
            <View style={styles.body}>
              <View style={styles.header}>
                <Text style={[commonStyle.textBold, styles.headerText]}>
                  תשלום
                </Text>
              </View>
              <FlatList
                data={options}
                style={styles.content}
                keyExtractor={(item, index) => String(index)}
                renderItem={({ item, index }) => {
                  return (
                    <View key={index} style={styles.option}>
                      <Text style={[commonStyle.textBold, styles.labelText]}>
                        {item.label}
                      </Text>
                      <View style={styles.inputView}>
                        {React.cloneElement(item.input, {
                          value: form[item.key],
                          onValChange: (val: any) => {
                            setForm({ ...form, [item.key]: val });
                          },
                        })}
                      </View>
                    </View>
                  );
                }}
              />
              <View
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Image
                  style={{
                    width: '80%',
                    height: 70,
                  }}
                  resizeMode="stretch"
                  source={payImage}
                />
              </View>
            </View>
            <View style={styles.buttonView}>
              <View
                style={[
                  styles.button,
                  disabled || loading ? styles.buttonDisabled : {},
                ]}>
                <TouchableOpacity
                  disabled={disabled || loading}
                  onPress={onSave}
                  style={styles.innerButton}>
                  {loading ? (
                    <Spinner size="medium" />
                  ) : (
                    <Text style={[commonStyle.text, styles.textButton]}>
                      לתשלום
                    </Text>
                  )}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
};
