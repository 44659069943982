/* eslint-disable react-native/no-inline-styles */
import React, { useContext } from 'react';
import { Dimensions, Keyboard, StyleSheet, Text, View } from 'react-native';
// import { Toast } from 'popup-ui';
// import { TouchableOpacity } from 'react-native-gesture-handler';
import TextButton from '../../../components/textButton';
import InputText from '../../../components/inputText';
import commonStyle from '../../../common/style';
import { IStepScreens } from '../../../common/interfaces';
import { parsePhoneNumber } from '../services/user';
import { useKeyboard } from '../../../common/hooks';
import { AuthContext } from '../../../context/authentication';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 150,
  },
  titleText: {
    fontSize: 32,
    paddingBottom: 20,
  },
  containerInput: {
    width: '70%',
    marginBottom: 20,
  },
  containerButton: {
    height: 125,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
});

export default ({
  onNext = () => Promise.resolve({ status: 'ok' }),
  values,
  setValues = () => {},
}: IStepScreens) => {
  const isKeyboardVisible = useKeyboard();
  const { emailLogin } = useContext(AuthContext);
  return (
    <View
      style={[
        styles.container,
        isKeyboardVisible && Dimensions.get('screen').height < 720
          ? { paddingTop: 60 }
          : {},
      ]}>
      <Text style={[commonStyle.text, styles.titleText]}>כניסה למערכת</Text>
      <View style={styles.containerInput}>
        {emailLogin ? (
          <InputText
            value={values!.email || ''}
            onValChange={(val) => {
              setValues({ ...values, email: val });
            }}
            type="text"
            placeholder="מייל"
          />
        ) : (
          <></>
        )}
      </View>
      <View style={[styles.containerInput]}>
        <InputText
          value={emailLogin ? values!.password : values!.phone || ''}
          onValChange={(val) => {
            if (emailLogin) {
              setValues({ ...values, password: val });
            } else {
              setValues({ ...values, phone: parsePhoneNumber(val) });
            }
          }}
          type={emailLogin ? 'password' : 'phone'}
          placeholder={emailLogin ? 'סיסמה' : 'טלפון'}
        />
      </View>
      <View style={[styles.containerButton]}>
        <TextButton
          title="המשך"
          onClick={async () => {
            Keyboard.dismiss();
            const toast = await onNext();
            if (toast?.status === 'error' || toast?.status === 'info') {
              // Toast.show({
              //   title: toast.title,
              //   text: toast.msg,
              //   color: '#FF005A',
              // });
            }
          }}
          style={{
            width: 140,
            fontSize: 20,
            height: 6,
            background: '#05a4ff',
            color: '#1a1a1a',
            disable: false,
          }}
        />
      </View>
    </View>
  );
};
