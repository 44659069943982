/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-native/no-inline-styles */
import React, { useEffect } from 'react';
import {
  Dimensions,
  Keyboard,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
// import { Toast } from 'popup-ui';
import TextButton from '../../../components/textButton';
import commonStyle from '../../../common/style';
import InputText from '../../../components/inputText';
import { IStepScreens } from '../../../common/interfaces';
import { MaterialIcons } from '@expo/vector-icons';
import { useKeyboard } from '../../../common/hooks';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 150,
  },
  titleText: {
    fontSize: 28,
    paddingBottom: 5,
  },
  subTitle: {
    fontSize: 18,
    paddingBottom: 5,
    color: '#6f6f6f',
  },
  containerInput: {
    width: '70%',
    marginTop: 20,
    marginBottom: 20,
  },
  containerButton: {
    height: 125,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  backButton: {
    position: 'absolute',
    right: 3,
    top: 15,
  },
});

export default ({
  onNext = () => Promise.resolve({ status: 'ok' }),
  context,
  values,
  onBack,
  setValues,
}: IStepScreens) => {
  const isKeyboardVisible = useKeyboard();

  return (
    <View
      style={[
        styles.container,
        isKeyboardVisible && Dimensions.get('screen').height < 720
          ? { paddingTop: 60 }
          : {},
      ]}>
      <TouchableOpacity
        style={styles.backButton}
        onPress={() => {
          onBack && onBack();
          // navigation.goBack();
        }}>
        <MaterialIcons name="navigate-next" size={45} color="#E1E3E3" />
      </TouchableOpacity>
      <Text style={[commonStyle.text, styles.titleText]}>אימות טלפון</Text>
      <Text style={[commonStyle.text, styles.subTitle]}>
        נא הזן את הקוד שנשלח למספר
      </Text>
      <Text style={[commonStyle.text, styles.subTitle]}>
        {context ? context[1].phone : ''}
      </Text>
      <View style={styles.containerInput}>
        <InputText
          onValChange={(val: any) => {
            setValues && setValues({ ...values, validation: val });
          }}
          value={values!.validation}
          type="number"
          placeholder="קוד האימות"
        />
      </View>
      <View style={[styles.containerButton]}>
        <TextButton
          title="המשך"
          onClick={async () => {
            Keyboard.dismiss();
            const toast = await onNext();
            if (toast?.status === 'error' || toast?.status === 'info') {
              // Toast.show({
              //   title: toast.title,
              //   text: toast.msg,
              //   color: '#FF005A',
              // });
            }
          }}
          style={{
            width: 140,
            fontSize: 20,
            height: 6,
            background: '#05a4ff',
            color: '#1a1a1a',
            disable: false,
          }}
        />
      </View>
    </View>
  );
};
