/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/no-array-index-key */
import moment from 'moment';
import React, { useState } from 'react';
import { StyleSheet, View, TouchableOpacity, Text, Image } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Avatar from '../../assets/dickHead.jpg';
import commonStyle from '../common/style';
import ImagePreview from './imagePreview';

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#F6F3F3',
  },
  profile: {
    height: 180,
    width: '100%',
    justifyContent: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    alignItems: 'center',
  },
  profileContent: {
    flex: 1,
    paddingTop: 3,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dividerView: {
    flex: 0.05,
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
  options: {
    flex: 1,
    width: '100%',
  },
  avatar: {
    width: 80,
    height: 80,
    borderRadius: 40,
    shadowOpacity: 0.2,
    shadowRadius: 10,
    shadowOffset: {
      height: 0,
      width: 0,
    },
    elevation: 5,
  },
  avatarImg: {
    width: 80,
    height: 80,
    borderRadius: 40,
    resizeMode: 'stretch',
  },
  divider: {
    flex: 0.1,
    width: '100%',
    borderRadius: 5,
    backgroundColor: '#DEDEDE',
  },
  text: {
    paddingTop: 5,
    fontSize: 22,
    textAlign: 'center',
    color: '#222222',
  },
  subText: {
    textAlign: 'center',
    color: '#222222',
  },
  option: {
    height: 50,
    width: '100%',
    alignItems: 'center',
  },
  textOption: {
    color: '#1F2024',
    paddingRight: 15,
    fontSize: 18,
  },
  viewContent: {
    paddingHorizontal: 25,
    flexDirection: 'row-reverse',
    alignItems: 'center',
    alignSelf: 'flex-end',
    width: '100%',
    flex: 1,
  },
  optionDividerView: {
    width: '100%',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flex: 0.1,
  },
  dividerOption: {
    height: 1,
    width: '100%',
    borderRadius: 20,
    borderTopColor: '#E0DADA',
    borderEndColor: '#FEFDFD',
    borderTopWidth: 0.5,
    borderEndWidth: 0.5,
  },
});

const SideBar = (props: {
  name: string;
  profileImg: string | null;
  state: any;
  descriptors: any;
  navigation: any;
  subscribe: any;
}) => {
  const { state, descriptors, navigation, name, profileImg, subscribe } = props;
  const insets = useSafeAreaInsets();
  const [visible, setVisible] = useState(false);
  console.log(subscribe);

  return (
    <>
      <ImagePreview
        image={profileImg as any}
        visible={visible}
        setVisible={() => setVisible(false)}
      />
      <View style={[styles.root, { paddingTop: insets.top }]}>
        <View style={styles.profile}>
          <View style={styles.profileContent}>
            <TouchableOpacity onPress={() => setVisible(true)}>
              <View style={styles.avatar}>
                {profileImg ? (
                  <Image
                    style={styles.avatarImg}
                    source={{ uri: profileImg }}
                  />
                ) : (
                  <Image style={styles.avatarImg} source={Avatar} />
                )}
              </View>
            </TouchableOpacity>
            <Text style={[commonStyle.text, styles.text]}>{name}</Text>
            {subscribe ? (
              <Text
                style={[
                  commonStyle.text,
                  styles.subText,
                ]}>{`מנוי בתוקף עד: ${moment(subscribe).format(
                'YYYY-MM-DD',
              )}`}</Text>
            ) : (
              <></>
            )}
          </View>
          <View style={styles.dividerView}>
            <View style={styles.divider} />
          </View>
        </View>
        <View style={styles.options}>
          {state!.routes.map((route: any, index: any) => {
            const { options } = descriptors[route.key];
            const isFocused = state!.index === index;
            const onPress = () => {
              const event = navigation!.emit({
                type: 'tabPress',
                target: route.key,
                data: { routeName: route.name },
                canPreventDefault: true,
              });
              if (!isFocused && !event.defaultPrevented) {
                navigation!.navigate(route!.name);
              }
            };
            return options.drawerLabel !== 'none' ? (
              <View key={index} style={styles.option}>
                <TouchableOpacity
                  style={{ width: '100%', flex: 1 }}
                  onPress={onPress}>
                  <View style={styles.viewContent}>
                    {React.cloneElement(options.drawerIcon, {
                      size: 20,
                      color: isFocused ? '#1F2024' : '#1F2024',
                    })}
                    <Text
                      style={[
                        commonStyle.text,
                        styles.textOption,
                        isFocused ? {} : {},
                      ]}>
                      {options.drawerLabel}
                    </Text>
                  </View>
                  <View style={styles.optionDividerView}>
                    <View style={styles.dividerOption} />
                  </View>
                </TouchableOpacity>
              </View>
            ) : (
              <React.Fragment key={index} />
            );
          })}
        </View>
      </View>
    </>
  );
};

export default SideBar;
