/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { StyleSheet, Image, View, ImageBackground } from 'react-native';
import { Spinner } from '@ui-kitten/components';
import { StatusBar } from 'expo-status-bar';
import logo from '../../../assets/splash_v2.png';
// import Background from '../../components/background';

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinnerView: {
    width: '100%',
    flex: 0.15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 400,
    height: 400,
  },
  background: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: '#1a1a1a',
    justifyContent: 'center',
    alignItems: 'center',
    resizeMode: 'stretch',
    opacity: 1,
  },
});

export default () => {
  return (
    <ImageBackground
      resizeMode={'stretch'}
      source={logo}
      style={styles.background}>
      <StatusBar style={'light'} translucent backgroundColor={'transparent'} />
      <View style={styles.root}>
        {/* <Image style={styles.image} source={logo} /> */}
      </View>
      <View style={styles.spinnerView}>
        <Spinner size="medium" />
      </View>
    </ImageBackground>
  );
};
