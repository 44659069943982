/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, StyleSheet, View } from 'react-native';
import { FirebaseRecaptchaVerifierModal } from 'expo-firebase-recaptcha';
import Background from '../../components/background';
import Progressbar from '../../components/progressBar';
import User from './pages/user';
import Phone from './pages/phone';
import Personal from './pages/personal/personal';
import userService from './services/user';
import phoneService from './services/phone';
import personalService from './services/personal';
import Terms from './pages/terms';

import firebase from '../../services/firebase';
import { AuthContext } from '../../context/authentication';
import termsService from './services/terms';

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
});

const pages = [
  {
    screen: <User />,
    run: userService,
    progressBar: true,
  },
  {
    screen: <Phone />,
    run: phoneService,
    progressBar: true,
  },
  {
    screen: <Terms />,
    run: termsService,
    progressBar: true,
  },
  {
    screen: <Personal />,
    run: personalService,
    progressBar: true,
  },
];

export default ({ navigation }: any) => {
  const { setAutoLogin, setEmailLogin, emailLogin } = useContext(AuthContext);
  useEffect(() => {
    setAutoLogin(false);
  }, []);
  const [step, setStep] = useState<any>(0);
  const [registrationInfo, setRegistrationInfo] = useState<any>([
    {},
    {},
    {},
    { length: 165 },
  ]);
  const [context, setContext] = useState<any>({
    emailLogin,
  });
  useEffect(() => {
    setTimeout(() => {
      (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback(response: any) {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
          },
          'expired-callback': function () {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          },
        },
      );
      setContext((prevState: any) => ({
        ...prevState,
        recaptcha: (window as any).recaptchaVerifier,
      }));
    }, 2000);
  });

  useEffect(() => {
    setContext({ ...context, emailLogin });
  }, [emailLogin]);
  React.useEffect(
    () =>
      navigation.addListener('beforeRemove', (e: any) => {
        e.preventDefault();
        Alert.alert(
          'Discard changes?',
          'You have unsaved changes. Are you sure to discard them and leave the screen?',
          [
            { text: "Don't leave", style: 'cancel', onPress: () => {} },
            {
              text: 'Discard',
              style: 'destructive',
              onPress: () => navigation.dispatch(e.data.action),
            },
          ],
        );
      }),
    [navigation],
  );

  const next = (end: boolean) => {
    if (end) {
      setAutoLogin(true);
    } else if (step + 1 !== pages.length) {
      setStep(step + 1);
    } else {
      setAutoLogin(true);
    }
  };
  const back = () => {
    if (step !== 0) {
      setStep(step - 1);
    }
  };
  return (
    <>
      <div id="recaptcha-container" />

      <Background type="light">
        <View style={styles.root}>
          {pages[step].progressBar ? (
            <Progressbar
              secretCallback={() => {
                setEmailLogin(true);
              }}
              steps={pages.filter((page) => page.progressBar).length}
              step={step + 1}
            />
          ) : (
            <></>
          )}
          {React.cloneElement(pages[step].screen as any, {
            onNext: async () => {
              console.log('aaaa');

              return pages[step].run(
                registrationInfo[step],
                context,
                (key, val) => setContext({ ...context, [key]: val }),
                next,
              );
            },
            onBack: back,
            values: registrationInfo[step] || {},
            context: [
              { ...registrationInfo[0], ...context },
              ...registrationInfo,
            ],

            setValues: (val: any) => {
              const tmpRegistrationInfo = registrationInfo;
              tmpRegistrationInfo[step] = val;
              setRegistrationInfo([...tmpRegistrationInfo]);
            },
          })}
        </View>
      </Background>
    </>
  );
};
