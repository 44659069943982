import React, { useContext, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { DrawerActions, useFocusEffect } from '@react-navigation/native';
// import { dismissAllNotificationsAsync } from 'expo-notifications';
import Avatar from '../../components/avatar';
import Background from '../../components/background';
import Catalog from '../../components/catalog';
import commonStyle from '../../common/style';

import Inbox from '../../components/inboxClass';
import SearchHeader from '../../components/searchHeader';
import Filter from '../filter/filter';
import { AccountContext } from '../../context/account';
import CatalogClass from '../../components/catalogClass';

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
  },
  header: {
    height: 70,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
  },
  main: {
    flex: 1,
    width: '100%',
    flexDirection: 'row-reverse',
  },
  catalog: {
    flex: 0.5,
    maxWidth: 160,
    justifyContent: 'center',
  },
  inbox: {
    flex: 1,
    justifyContent: 'center',
  },
});

export default ({ navigation }: any) => {
  const [filterVisible, setFilterVisible] = useState(false);
  const { account } = useContext(AccountContext);
  // useFocusEffect(() => {
  //   try {
  //     dismissAllNotificationsAsync();
  //   } catch (e) {
  //     console.log(e);
  //   }
  // });
  return (
    <Background color="#00A4FF">
      <View
        style={{
          width: 100,
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: 20,
          left: 5,
        }}>
        <Text style={[commonStyle.textSpecial, { fontSize: 24 }]}>Be My</Text>
        <Text style={[commonStyle.textSpecial, { fontSize: 24 }]}>Baby</Text>
      </View>
      <Filter
        visible={filterVisible}
        setVisible={() => setFilterVisible(!filterVisible)}
      />
      <View style={styles.root}>
        <View style={styles.header}>
          <SearchHeader onPress={() => setFilterVisible(!filterVisible)} />
          <Avatar
            name={account?.name || ''}
            imageUri={
              account && account.profile && account.profile[0]
                ? { uri: account.profile[0] }
                : { uri: '' }
            }
            onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}
          />
        </View>
        <View style={styles.main}>
          <View style={styles.catalog}>
            <CatalogClass navigation={navigation} />
          </View>
          <View style={styles.inbox}>
            <Inbox account={account} navigation={navigation} />
          </View>
        </View>
      </View>
    </Background>
  );
};
