/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState } from 'react';
import {
  View,
  StyleSheet,
  Text,
  ScrollView,
  Dimensions,
  Keyboard,
} from 'react-native';
import { compact, isEmpty } from 'lodash';
// import { Toast } from 'popup-ui';
import InputText from '../../../../components/inputText';
import Step from './step';
import commonStyle from '../../../../common/style';
import DateInput from '../../../../components/dateInput';
import RadioGroup from '../../../../components/radioGroup';
import ImagesInput from '../../../../components/imagesInput';
import NumericInput from '../../../../components/numericInput';
import { isDateValid } from '../../services/personal';

const style = StyleSheet.create({
  main: {
    flex: 1,
    width: '85%',
    paddingBottom: 15,
  },
  row: {
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    marginTop: 1,
  },
  text: {
    width: 130,
    fontSize: 19,
    textAlign: 'left',
    paddingLeft: 0,
  },
  inputView: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  infoContainer: {
    width: '90%',
    flex: 1,
    alignSelf: 'center',
    paddingTop: 15,
    paddingBottom: 15,
  },
});

const imageUploadStyle = StyleSheet.create({
  main: {
    height: '100%',
    width: '100%',
    paddingRight: 82,
    flex: 1,
  },
  scrollViewContentContainer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
});

export default ({
  onNext = () => Promise.resolve({ status: 'ok' }),
  values,
  registration = true,
  setValues,
  context,
}: any) => {
  const [stepIndex, setStepIndex] = useState(0);
  const stepsNotRegistered = [
    <Step
      title="נא להקליד שם / כינוי"
      validate={isEmpty(values.name)}
      input={
        <InputText
          placeholder="הקלד שם"
          style={{
            height: 40,
            bold: true,
            width: 200,
            color: '#88827A',
            marginBottom: 10,
            placeholderColor: '#88827A',
            fontSize: 16,
          }}
          value={values.name}
          onValChange={(val: any) => {
            setValues && setValues({ ...values, name: val });
          }}
        />
      }
    />,

    <Step
      validate={!isDateValid(values.birthday)}
      title="גיל שלי"
      input={
        <DateInput
          mode="count"
          style={{
            height: 40,
            bold: true,
            color: '#88827A',
            marginBottom: 10,
            fontSize: 16,
          }}
          mode="count"
          value={values.birthday || new Date()}
          onValChange={(val: any) => {
            console.log(values);

            setValues && setValues({ ...values, birthday: val });
          }}
        />
      }
    />,
    <Step
      title="הגובה שלי"
      validate={!isEmpty(values.length)}
      input={
        <NumericInput
          style={{
            height: 40,
            bold: true,
            color: '#88827A',
            marginBottom: 10,
            fontSize: 16,
          }}
          value={values.length}
          onValChange={(val: any) => {
            setValues && setValues({ ...values, length: val });
          }}
        />
      }
    />,
    <Step
      title="מבנה הגוף שלי"
      validate={isEmpty(values.body)}
      input={
        <RadioGroup
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            activeBackgroundColor: '#FF005A',
            activeColor: '#FFFFFF',
          }}
          value={values.body}
          onValChange={(val: any) => {
            setValues && setValues({ ...values, body: val });
          }}
          options={['רזה', 'חטוב', 'עודף', 'מלא']}
        />
      }
    />,
    // <Step
    //   title="מצב משפחתי"
    //   validate={isEmpty(values.status)}
    //   input={
    //     <RadioGroup
    //       style={{
    //         justifyContent: 'center',
    //         alignItems: 'center',
    //         activeBackgroundColor: '#FF005A',
    //         activeColor: '#FFFFFF',
    //       }}
    //       value={values.status}
    //       onValChange={(val: any) => {
    //         setValues && setValues({ ...values, status: val });
    //       }}
    //       options={['רווק/ה', 'גרוש/ה', 'נשוי/אה', 'אלמנ/ה']}
    //     />
    //   }
    // />,
    <Step
      title="סוג קשר שמעניין אותי"
      validate={isEmpty(compact(values!.relationship || []))}
      input={
        <RadioGroup
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: 130,
            activeBackgroundColor: '#FF005A',
            activeColor: '#FFFFFF',
          }}
          array
          value={values.relationship}
          onValChange={(val: any) => {
            setValues && setValues({ ...values, relationship: val });
          }}
          options={['סידור', 'בילויים']}
        />
      }
    />,
    <Step
      validate={isEmpty(values.about)}
      title="כמה מילים עלי"
      input={
        <View style={style.infoContainer}>
          <InputText
            style={{
              borderRadius: 15,
              multiLine: true,
              color: '#222222',
              width: '100%' as any,
              height: 70,
            }}
            value={values.about}
            onValChange={(val: any) => {
              setValues && setValues({ ...values, about: val });
            }}
          />
        </View>
      }
    />,
    <Step
      title="הוספת תמונה"
      subTitle="למי שאינו מעוניין לצרף תמונה, תופיע תמונת אילוסטרציה כברירת מחדל"
      input={
        <UploadImage values={values} setValues={setValues} context={context} />
      }
    />,
  ];
  const steps = [
    <Step
      title="נא להקליד שם / כינוי"
      validate={isEmpty(values.name)}
      input={
        <InputText
          placeholder="הקלד שם"
          style={{
            height: 40,
            bold: true,
            width: 200,
            color: '#88827A',
            marginBottom: 10,
            placeholderColor: '#88827A',
            fontSize: 16,
          }}
          value={values.name}
          onValChange={(val: any) => {
            setValues && setValues({ ...values, name: val });
          }}
        />
      }
    />,
    <Step
      validate={isEmpty(values.interesting) || isEmpty(values.sex)}
      input={<Preferences values={values} setValues={setValues} />}
    />,
    <Step
      validate={!isDateValid(values.birthday)}
      title="גיל שלי"
      input={
        <DateInput
          style={{
            height: 40,
            bold: true,
            color: '#88827A',
            marginBottom: 10,
            fontSize: 16,
          }}
          mode="count"
          value={values.birthday || new Date()}
          onValChange={(val: any) => {
            console.log(values);

            setValues && setValues({ ...values, birthday: val });
          }}
        />
      }
    />,
    <Step
      title="הגובה שלי"
      validate={!isEmpty(values.length)}
      input={
        <NumericInput
          style={{
            height: 40,
            bold: true,
            color: '#88827A',
            marginBottom: 10,
            fontSize: 16,
          }}
          value={values.length}
          onValChange={(val: any) => {
            setValues && setValues({ ...values, length: val });
          }}
        />
      }
    />,
    <Step
      title="מבנה הגוף שלי"
      validate={isEmpty(values.body)}
      input={
        <RadioGroup
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            activeBackgroundColor: '#FF005A',
            activeColor: '#FFFFFF',
          }}
          value={values.body}
          onValChange={(val: any) => {
            setValues && setValues({ ...values, body: val });
          }}
          options={['רזה', 'חטוב', 'עודף', 'מלא']}
        />
      }
    />,
    // <Step
    //   title="מצב משפחתי"
    //   validate={isEmpty(values.status)}
    //   input={
    //     <RadioGroup
    //       style={{
    //         justifyContent: 'center',
    //         alignItems: 'center',
    //         activeBackgroundColor: '#FF005A',
    //         activeColor: '#FFFFFF',
    //       }}
    //       value={values.status}
    //       onValChange={(val: any) => {
    //         setValues && setValues({ ...values, status: val });
    //       }}
    //       options={['רווק/ה', 'גרוש/ה', 'נשוי/אה', 'אלמנ/ה']}
    //     />
    //   }
    // />,
    <Step
      title="סוג קשר שמעניין אותי"
      validate={isEmpty(compact(values!.relationship || []))}
      input={
        <RadioGroup
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: 130,
            activeBackgroundColor: '#FF005A',
            activeColor: '#FFFFFF',
          }}
          array
          value={values.relationship}
          onValChange={(val: any) => {
            setValues && setValues({ ...values, relationship: val });
          }}
          options={['סידור', 'בילויים']}
        />
      }
    />,
    <Step
      validate={isEmpty(values.about)}
      title="כמה מילים עלי"
      input={
        <View style={style.infoContainer}>
          <InputText
            style={{
              borderRadius: 15,
              multiLine: true,
              color: '#222222',
              width: '100%' as any,
              height: 70,
            }}
            value={values.about}
            onValChange={(val: any) => {
              setValues && setValues({ ...values, about: val });
            }}
          />
        </View>
      }
    />,
    <Step
      title="הוספת תמונה"
      subTitle="למי שאינו מעוניין לצרף תמונה, תופיע תמונת אילוסטרציה כברירת מחדל"
      input={
        <UploadImage values={values} setValues={setValues} context={context} />
      }
    />,
  ];

  const currSteps = registration ? steps : stepsNotRegistered;

  return React.cloneElement(currSteps[stepIndex], {
    index: stepIndex,
    onNext: async () => {
      if (stepIndex === currSteps.length - 1) {
        Keyboard.dismiss();
        const toast = await onNext();
        setStepIndex(0);
        if (toast?.status === 'error' || toast?.status === 'info') {
          // Toast.show({
          //   title: toast.title,
          //   text: toast.msg,
          //   color: '#FF005A',
          // });
        }
      } else {
        setStepIndex(stepIndex + 1);
      }
    },
  });
};

const UploadImage = ({ values, setValues, context }: any) => {
  return (
    <ScrollView
      horizontal
      snapToAlignment="center"
      contentContainerStyle={imageUploadStyle.scrollViewContentContainer}
      snapToInterval={Dimensions.get('window').width / 5}
      style={[imageUploadStyle.main]}>
      <ImagesInput
        type="album"
        value={values!.album}
        userId={
          !isEmpty(context as any) &&
          (context as any)[0].user &&
          (context as any)[0].user.user
            ? (context as any)[0]!.user!.user!.uid
            : ''
        }
        onValChange={(val: any) => {
          setValues && setValues({ ...values, album: val });
        }}
        imageCount={8}
      />
      <ImagesInput
        type="profile"
        value={values!.profile}
        userId={
          !isEmpty(context as any) &&
          (context as any)[0].user &&
          (context as any)[0].user.user
            ? (context as any)[0]!.user!.user!.uid
            : ''
        }
        onValChange={(val: any) => {
          setValues && setValues({ ...values, profile: val });
        }}
        imageCount={1}
      />
    </ScrollView>
  );
};

const Preferences = ({ values, setValues }: any) => {
  return (
    <View style={style.main}>
      <View
        style={{
          ...style.row,
          ...(Dimensions.get('window').width < 400
            ? {
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }
            : {}),
        }}>
        <Text
          style={[
            commonStyle.text,
            style.text,
            Dimensions.get('window').width < 400
              ? {
                  textAlign: 'center',
                }
              : {},
          ]}>
          אני
        </Text>
        <View style={style.inputView}>
          <RadioGroup
            style={
              {
                alignItems: 'center',
                justifyContent: 'center',
                activeBackgroundColor: '#FF005A',
                activeColor: '#FFFFFF',
              } as any
            }
            value={values.sex}
            onValChange={(val: any) => {
              setValues && setValues({ ...values, sex: val });
            }}
            options={['גבר', 'אישה']}
          />
        </View>
      </View>
      <View
        style={{
          ...style.row,
          ...(Dimensions.get('window').width < 400
            ? {
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }
            : {}),
        }}>
        <Text
          style={[
            commonStyle.text,
            style.text,
            Dimensions.get('window').width < 400
              ? {
                  marginTop: 10,
                  marginBottom: 10,
                  textAlign: 'center',
                }
              : {},
          ]}>
          מעוניין/ת להכיר
        </Text>
        <View style={style.inputView}>
          <RadioGroup
            style={
              {
                alignItems: 'center',
                justifyContent: 'center',
                activeBackgroundColor: '#FF005A',
                activeColor: '#FFFFFF',
              } as any
            }
            value={values.interesting}
            onValChange={(val: any) => {
              setValues && setValues({ ...values, interesting: val });
            }}
            options={['גבר', 'אישה']}
          />
        </View>
      </View>
    </View>
  );
};
