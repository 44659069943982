/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useContext, useEffect, useState } from 'react';
import {
  ImageBackground,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
} from 'react-native';
import { getDistance } from 'geolib';
import { Skeleton } from 'react-native-magnus';
import { Asset } from 'expo-asset';
import { isEmpty } from 'lodash';
import { LinearGradient } from 'expo-linear-gradient';
import commonStyle from '../common/style';
import { AccountContext } from '../context/account';

const styles = StyleSheet.create({
  root: {
    width: '95%',
    height: 120,
    maxWidth: 200,
    alignSelf: 'center',
    borderRadius: 10,
    marginVertical: 10,
    zIndex: 1,
    elevation: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,

    overflow: 'hidden',
  },
  image: {
    borderRadius: 10,
    width: '100%',
    height: '100%',
  },
  info: {
    alignSelf: 'center',
    height: 25,
    zIndex: 20,
    position: 'absolute',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 0,
    width: '95%',
  },
  sideText: {
    position: 'absolute',
    top: 4,
    color: '#FFFEFE',
    zIndex: 15,
    fontSize: 12,
    right: 8,
  },
  divider: {
    backgroundColor: '#FDFCFC',
    width: 2,
    height: '55%',
    marginHorizontal: 5,
    zIndex: 15,
  },
  text: {
    color: '#FFFFFF',
    zIndex: 50,
    elevation: 20,
    fontSize: 11.5,
  },
  itemLoading: {
    width: '90%',
    height: 120,

    maxWidth: 200,

    backgroundColor: '#2B2B2B',
    alignSelf: 'center',
    overflow: 'hidden',
    borderRadius: 10,
    marginVertical: 10,
    zIndex: 1,
    elevation: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
  },
  name: {
    textAlign: 'center',
  },
  status: {
    width: 38,
    textAlign: 'center',
  },
  fadeTop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: 60,
    zIndex: 5,
  },
  fadeBottom: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 120,
    zIndex: 5,
  },
  loadingView: {
    height: 50,
    width: '100%',
    marginBottom: 25,
    marginRight: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export interface ICatalogItemProps {
  onPress: () => void;
  item: any;
  loading: boolean;
}

const CatalogItem = ({ onPress, item, loading }: ICatalogItemProps) => {
  const { account } = useContext(AccountContext);
  const [image, setImage] = useState('');
  useEffect(() => {
    if (!isEmpty(item.profile)) {
      Asset.loadAsync(item.profile[0])
        .then(async (assets) => {
          if (!isEmpty(assets)) {
            setImage(assets[0].localUri as any);
          }
        })
        .catch(() => {
          setImage(item.profile[0] as any);
        });
    }
  }, [item]);
  return loading || !image ? (
    <CatalogItemLoading />
  ) : (
    <TouchableOpacity onPress={onPress} style={styles.root}>
      <ImageBackground
        style={styles.image}
        resizeMode="stretch"
        source={{
          uri: image,
        }}>
        <LinearGradient
          colors={['rgba(0,0,0,0.3)', 'rgba(255,255,255,0)']}
          style={styles.fadeTop}
        />

        <Text style={[commonStyle.textBold, styles.sideText]}>{`${(
          getDistance(
            account?.coords || {
              latitude: 32.0852997,
              longitude: 34.7818064,
            },
            item.coords || { latitude: 32.0852997, longitude: 34.7818064 },
          ) / 1000
        ).toFixed(0)} ק"מ`}</Text>
        <View style={styles.info}>
          <Text
            numberOfLines={1}
            style={[commonStyle.textBold, styles.text, styles.name]}>
            {item.name as string}
          </Text>
          <View style={[styles.divider]} />
          <Text style={[commonStyle.textBold, styles.text]}>
            {new Date(Date.now()).getFullYear() -
              (new Date(item.birthday) as Date).getFullYear()}
          </Text>
        </View>
        <LinearGradient
          colors={['rgba(255,255,255,0)', 'rgba(0,0,0,0.4)']}
          style={styles.fadeBottom}
        />
      </ImageBackground>
    </TouchableOpacity>
  );
};

const CatalogItemLoading = React.memo(() => {
  return (
    <View style={styles.itemLoading}>
      <Skeleton.Box
        style={{ justifyContent: 'center', alignItems: 'center' }}
        rounded="lg"
        h="140px"
        w="110%"
        duration={1000}
        bg="gray800">
        <View style={styles.loadingView}>
          <Text style={[commonStyle.textBold, { fontSize: 21 }]}>מחפש</Text>
          <Text style={[commonStyle.textBold, { fontSize: 21 }]}>התאמות</Text>
        </View>
      </Skeleton.Box>
    </View>
  );
});

export default CatalogItem;
