/* eslint-disable @typescript-eslint/no-unused-vars */
export default async (
  values: any = {},
  context: any,
  setContext: (key: any, val: any) => void,
  next: (end: boolean) => void,
) => {
  next(false);
  return { status: 'ok' };
};
