import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import PaymentModal from './paymentModal';
import firebase from '../services/firebase';

import commonStyle from '../common/style';

const styles = StyleSheet.create({
  hide: {
    position: 'absolute',
    flex: 1,
    height: '100%',
    marginTop: 40,
    zIndex: 10,
    opacity: 0.98,
    width: '100%',
    paddingTop: 20,
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#222222',
  },
  button: {
    borderRadius: 8,
    backgroundColor: '#EA0554',
    paddingVertical: 8,
    color: '#EFEDEB',
    opacity: 1,
    width: '80%',
    fontSize: 18,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 12,
    zIndex: 20,
    elevation: 5,
  },
  buttonText: {
    color: '#EFEDEB',
    fontSize: 18,
    width: '100%',
    textAlign: 'center',
  },
  title: {
    color: '#EFEDEB',
    fontSize: 22,
  },
  subTitle: {
    color: '#EFEDEB',
    fontSize: 18,
    marginBottom: 10,
  },
});

export interface ISubscribeProps {
  receive: string;
  userId: string;
}

const Subscribe = ({ receive, userId }: ISubscribeProps) => {
  const [options, setOptions] = useState({
    halfYear: 100,
    month: 20,
    quarterly: 50,
    year: 200,
  });
  const [show, setShow] = useState(false);
  const [type, setType] = useState<'month' | 'quarterly' | 'halfYear' | 'year'>(
    'month',
  );

  useEffect(() => {
    const db = firebase.firestore();
    db.collection('Config')
      .doc('subscribePrice')
      .get()
      .then((res: any) => {
        setOptions(res.data());
      });
  });
  const onPress = (currType: 'month' | 'quarterly' | 'halfYear' | 'year') => {
    setType(currType);
    setShow(true);
  };
  return (
    <>
      <PaymentModal
        type={type}
        userId={userId}
        visible={show}
        setVisible={() => {
          setShow(false);
        }}
      />
      <View style={styles.hide}>
        <Text style={[commonStyle.textBold, styles.title]}>
          {`כדי לשוחח עם ${receive}`}
        </Text>
        <Text style={[commonStyle.textBold, styles.subTitle]}>בחר מנוי:</Text>
        <TouchableOpacity
          onPress={() => onPress('month')}
          style={styles.button}>
          <Text style={[commonStyle.textBold, styles.buttonText]}>
            חודש אחד
          </Text>
          <Text
            style={[
              commonStyle.textBold,
              styles.buttonText,
            ]}>{`${options.month} ש"ח`}</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => onPress('quarterly')}
          style={styles.button}>
          <Text style={[commonStyle.textBold, styles.buttonText]}>
            שלושה חודשים
          </Text>
          <Text
            style={[
              commonStyle.textBold,
              styles.buttonText,
            ]}>{`${options.quarterly} ש"ח`}</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => onPress('halfYear')}
          style={styles.button}>
          <Text style={[commonStyle.textBold, styles.buttonText]}>חצי שנה</Text>
          <Text
            style={[
              commonStyle.textBold,
              styles.buttonText,
            ]}>{`${options.halfYear} ש"ח`}</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => onPress('year')} style={styles.button}>
          <Text style={[commonStyle.textBold, styles.buttonText]}>שנה</Text>
          <Text
            style={[
              commonStyle.textBold,
              styles.buttonText,
            ]}>{`${options.year} ש"ח`}</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

export default Subscribe;
